import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const fetchUserAuthForLike = createAsyncThunk(
	'userAuthForLike/UserAuthFetchForLike',
	async () => {
		const token = await Cookies.get('token')
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_KEY}/auth/authorization`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		)
		return data
	}
)
const initialState = {
	userAuthLike: {},
	statusAuthLike: 'loading', // loading | success | error
}

const UserAuthLikeSlice = createSlice({
	name: 'userAuthLike',
	initialState,
	reducers: {
		setUserAuth(state, action) {
			state.userAuthLike = action.payload
			state.statusAuthLike = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUserAuthForLike.pending, (state, action) => {
				state.statusAuthLike = 'loading'
			})
			.addCase(fetchUserAuthForLike.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.userAuthLike = action.payload.user
				state.statusAuthLike = 'success'
			})
			.addCase(fetchUserAuthForLike.rejected, (state, action) => {
				state.userAuthLike = {}
				state.statusAuthLike = 'error'
			})
	},
})

export const { setUserAuth } = UserAuthLikeSlice.actions

export default UserAuthLikeSlice.reducer
