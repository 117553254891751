import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const fetchUserAuth = createAsyncThunk(
	'userAuth/UserAuthFetch',
	async () => {
		const token = Cookies.get('token')
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_KEY}/auth/authorization`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		)
		return data
	}
)
const initialState = {
	userAuth: {},
	statusAuth: 'loading', // loading | success | error
}

const FetchUserAuthSlice = createSlice({
	name: 'userAuth',
	initialState,
	reducers: {
		setUserAuth(state, action) {
			state.userAuth = action.payload
			state.statusAuth = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUserAuth.pending, (state, action) => {
				state.userAuth = {}
				state.statusAuth = 'loading'
			})
			.addCase(fetchUserAuth.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.userAuth = action.payload.user
				state.statusAuth = 'success'
			})
			.addCase(fetchUserAuth.rejected, (state, action) => {
				state.userAuth = {}
				state.statusAuth = 'error'
			})
	},
})

export const { setUserAuth } = FetchUserAuthSlice.actions

export default FetchUserAuthSlice.reducer
