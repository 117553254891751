import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCountPagination } from '../../redux/reducers/PaginationButtonSlice'
import style from './PaginationButton.module.scss'
const PaginationButton = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { count } = useSelector(state => state.paginationData)
	const handleClickPagination = () => {
		dispatch(setCountPagination(count + 20))
	}
	return (
		<button
			onClick={handleClickPagination}
			className={style.wrapperPaginationButton}
		>
			{t('seeMore')}
		</button>
	)
}

export default PaginationButton
