/** @format */

import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import A from "../../assets/header/A.svg";
import dvix from "../../assets/header/dvix.svg";
import telegram from "../../assets/telegram-svgrepo-com.svg";
import { setClickLogin } from "../../redux/reducers/ClickedLoginSlice";
import { fetchGeneratedToken } from "../../redux/reducers/GenerateTokenSlice";
import { setCatalog } from "../../redux/reducers/SelectedCatalogSlice";
import { fetchUserAuth } from "../../redux/reducers/UserSliceAuth";
import { SwitchButton } from "../Switcher2/Switcher/Switcher";
import SwitchLang from "./switchLang/SwitchLang";

import style from "./Header.module.scss";
const Header = () => {
  const { t } = useTranslation();
  const [clickedHotBtn, setClickedHotBtn] = useState(false);
  const [clickedCatalogBtn, setClickedCatalogBtn] = useState(false);
  const [clickedLogin, setClickedLogin] = useState(false);
  const dispatch = useDispatch();
  const handleClickHotBtn = () => {
    setClickedHotBtn(!clickedHotBtn);
    setClickedCatalogBtn(false);
    dispatch(setCatalog({ label: "Горящие", state: true }));
  };
  const handleClickCatalogBtn = () => {
    setClickedCatalogBtn(!clickedCatalogBtn);
    setClickedHotBtn(false);
    dispatch(setCatalog({ label: "Каталог", state: true }));
  };
  const { pages } = useSelector((state) => state.selectedCatalog);

  const { user, status } = useSelector((state) => state.userData);
  const { theme } = useSelector((state) => state.theme);
  const token = Cookies.get("token");

  useEffect(() => {
    if (token !== undefined) {
      dispatch(fetchUserAuth());
    }
  }, []);
  useEffect(() => {
    if (!theme) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);
  const [tokenResp, setTokenResp] = useState("");
  const { logined } = useSelector((state) => state.loginData);
  const { userAuth, statusAuth } = useSelector((state) => state.userDataAuth);
  const navigate = useNavigate();
  const handlerPopupAuth = () => {
    if (statusAuth === "success") {
      navigate("/profile/settings");
      return;
    }
    /*  dispatch(setClickedLogin(true)) */
    //setClickedLogin(!clickedLogin)
    // setClickedLogin(!clickedLogin)
    dispatch(setClickLogin(!logined));
    const tokenLocal = uuidv4();
    console.log("tokenLocal", tokenLocal);
    dispatch(fetchGeneratedToken({ tokenLocal }));
  };

  const checkStatusUser = (status, statusAuth, user, userAuth) => {
    if (status === "loading" && statusAuth === "success") {
      return userAuth;
    }
    if (status === "success") {
      return user;
    }
  };
  const result = checkStatusUser(status, statusAuth, user, userAuth);

  return (
    <header className={style.wrapperHeader}>
      <a
        className={style.toPath}
        href={`${process.env.REACT_APP_FRONT}/channels`}
      >
        <img src={A} alt="A" />
        <img src={dvix} alt="dvix" />
      </a>

      <div className={style.catalogHeader}>
        <button
          onClick={handleClickCatalogBtn}
          className={
            pages.label !== "Горящие"
              ? `${style.clickedCatalogBtn}`
              : `${style.nonClickedCatalogBtn}`
          }
        >
          <span>{t("catalog")}</span>
          <div
            className={pages.label !== "Горящие" ? `${style.underline}` : ``}
          ></div>
        </button>
        <button
          onClick={handleClickHotBtn}
          className={
            pages.label === "Горящие"
              ? `${style.clickedHotBtn}`
              : `${style.nonClickedHotBtn}`
          }
        >
          <span>{t("hot")}</span>
          <div
            className={
              pages.label === "Горящие" ? `${style.underlineYellow}` : ``
            }
          ></div>
        </button>
      </div>
      <div className={style.lastWrapperInHeader}>
        <SwitchLang />
        <SwitchButton />
        <button onClick={handlerPopupAuth} className={style.LoginBtn}>
          <img
            src={
              result !== undefined
                ? `${process.env.REACT_APP_API_KEY}/bot/${result.link_image}`
                : telegram
            }
            alt="telegram"
          />
          <span>
            {result !== undefined ? result.user_name : `${t("sighIn")}`}
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
