import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const fetchSubs = createAsyncThunk('getSubs/getSubsFetch', async () => {
	const API_BASE_URL = process.env.REACT_APP_API_KEY
	const token = Cookies.get('token')
	const { data } = await axios.get(`${API_BASE_URL}/subs/all_sub`, {
		headers: { Authorization: `Bearer ${token}` },
	})
	console.log(data)
	return data
})
const initialState = {
	subs: [],
	statusSubs: 'loading', // loading | success | error
}

const getAllSubsSlice = createSlice({
	name: 'subs',
	initialState,
	reducers: {
		setSubs(state, action) {
			state.subs = action.payload
			state.statusSubs = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSubs.pending, (state, action) => {
				state.statusSubs = 'loading'
			})
			.addCase(fetchSubs.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.subs = action.payload

				state.statusSubs = 'success'
			})
			.addCase(fetchSubs.rejected, (state, action) => {
				state.subs = []
				state.statusSubs = 'error'
			})
	},
})

export default getAllSubsSlice.reducer
