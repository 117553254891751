import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	sort: {
		order: 'asc',
		name: '',
		is_sort: false,
	},
}

const SortingSlice = createSlice({
	name: 'sort',
	initialState,
	reducers: {
		setSort(state, action) {
			state.sort = action.payload
		},
		deleteSort(state) {
			state.sort = {}
		},
	},
})
export const { setSort, deleteSort } = SortingSlice.actions

export default SortingSlice.reducer
