import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { setClickLogin } from '../../../redux/reducers/ClickedLoginSlice'
import { fetchGeneratedToken } from '../../../redux/reducers/GenerateTokenSlice'
import { setIsLoginedSlice } from '../../../redux/reducers/IsLoginedSlice'
import style from './AddChannel.module.scss'
const AddChannelBtn = ({ statusAuth }) => {
	const { t } = useTranslation()
	const { isLogin } = useSelector(state => state.loginTgData)
	console.log('statusAuth', statusAuth)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		console.log('statusAuthiiner, ', statusAuth, isLogin)
		if (statusAuth === 'success' && isLogin === true) {
			console.log('ddddd')
			navigate(`/profile/channels`)
			dispatch(setIsLoginedSlice(true))
		}
	}, [statusAuth, isLogin, navigate, dispatch])

	// const [isClick, setIsClick] = useState(false)
	const { logined } = useSelector(state => state.loginData)

	return (
		<div
			onClick={() => {
				if (statusAuth !== 'success') {
					console.log('errordddddd')
					const tokenLocal = uuidv4()
					console.log('tokenLocal', tokenLocal)
					dispatch(setClickLogin(!logined))
					dispatch(fetchGeneratedToken({ tokenLocal }))
					dispatch(setIsLoginedSlice(true))
					// setIsClick(true)
				}
				if (statusAuth === 'success' && isLogin === null) {
					return navigate('/profile/channels')
				}
			}}
			// to={statusAuth === 'success' ? '/profile/channels' : '/channels'}
			className={style.wrapperBtnChannel}
		>
			{t('addChannelBir')}
		</div>
	)
}

export default AddChannelBtn
