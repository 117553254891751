import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const getSubOneFetch = createAsyncThunk(
	'getSub/getSubFetch',
	async channel_id => {
		const API_BASE_URL = process.env.REACT_APP_API_KEY
		const token = Cookies.get('token')
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
		const { data } = await axios.get(
			`${API_BASE_URL}/subs/one_sub?channel_id=${channel_id}`,
			config
		)
		console.log(data)
		return data
	}
)

const initialState = {
	sub: {},
	statusSub: 'loading', // loading | success | error
}

const getSubOneSlice = createSlice({
	name: 'sub',
	initialState,
	reducers: {
		setSubs(state, action) {
			state.sub = action.payload
			state.statusSub = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getSubOneFetch.pending, (state, action) => {
				state.statusSubs = 'loading'
			})
			.addCase(getSubOneFetch.fulfilled, (state, action) => {
				state.sub = action.payload
				state.statusSub = 'success'
			})
			.addCase(getSubOneFetch.rejected, (state, action) => {
				state.sub = {}
				state.statusSub = 'error'
			})
	},
})

export default getSubOneSlice.reducer
