import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
export const fetchFavoritePut = createAsyncThunk(
	'favoritePut1/favoritePutFetch1',
	async obj => {
		const { data } = await axios.put(
			`${process.env.REACT_APP_API_KEY}/user-channel-selection/select`,

			{
				user_id: obj.user_id,
				channel_id: obj.channel_id,
				statusDelete: obj.statusDelete,
			}
		)

		return data
	}
)
const initialState = {
	favorite: {},
	statusFavorite: 'loading', // loading | success | error
	statusDelete: null,
}

const FavoritePutSlice = createSlice({
	name: 'favoritePut',
	initialState,
	reducers: {
		setFavorite(state, action) {
			state.favoritePut = action.payload
			state.statusFavorite = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchFavoritePut.pending, (state, action) => {
				state.statusFavorite = 'loading'
				state.statusDelete = 'loading'
			})
			.addCase(fetchFavoritePut.fulfilled, (state, action) => {
				let some = false
				if (action.payload && action.payload.message) {
					some = true
				}

				state.statusDelete = some
				state.favorite = action.payload

				state.statusFavorite = 'success'
			})
			.addCase(fetchFavoritePut.rejected, (state, action) => {
				state.favorite = {}
				state.statusDelete = false
				state.statusFavorite = 'error'
			})
	},
})

export default FavoritePutSlice.reducer
