/** @format */

import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import done from '../../assets/done.svg'
import done2 from '../../assets/done2.svg'
import iconSearch from '../../assets/search.svg'
import { setFilters } from '../../redux/reducers/FilterChannelSlice'
import { setCategoryOrLang } from '../../redux/reducers/SelectedCategoriesSlice'
import style from './DropDownFilt.module.scss'
const DropDownLang = ({ default_arg }) => {
	const fetchLanguage = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_KEY}/languages/all`
			)
			const newArr = data.result.map(element => {
				return element.name_lang
			})
			setArgs(newArr)
		} catch (error) {
			console.log(error)
		}
	}
	const [args, setArgs] = useState([])
	useEffect(() => {
		fetchLanguage()
	}, [])

	const [isHovered, setIsHovered] = useState(false)
	const [clicked, setClicked] = useState(false)
	const [lastElement, setLastElement] = useState(null)
	const [clickedHidden, setClickedHidden] = useState(false)
	const { filters } = useSelector(state => state.filterChannel)
	const refsLi = useRef([])
	let catMenu = useRef()

	const [selectedElement, setSelectedElement] = useState(['Не выбрано'])
	const { selectedCategoryOrLang } = useSelector(
		state => state.selectedCategoryOrLangData
	)
	const dispatch = useDispatch()
	const [selectedElementIndex, setSelectedElementIndex] = useState(null)
	const [clickedElement, setClickedElement] = useState([])
	const [isMore, setIsMore] = useState(false)
	useEffect(() => {
		let handler = e => {
			if (!catMenu.current.contains(e.target)) {
				setClickedHidden(false)
			}
		}

		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	})
	const findSelectedElement = (arr, element) => {
		let isFind = false
		arr.map(el => {
			if (el === 'Не выбрано' || el === '') {
				return (isFind = false)
			}
			if (el === element) {
				return (isFind = true)
			}
		})
		return isFind
	}
	const deleteSelectedElement = (arr, element) => {
		let result = []
		result = arr.filter(el => {
			if (el !== element) {
				return element
			}
		})
		if (result.length === 0) {
			result = ['Не выбрано']
		}
		if (result.length < 3) {
			setIsMore(false)
		}
		return result
	}
	const handleClickedElement = (element, index) => {
		setClicked(true)

		if (findSelectedElement(selectedCategoryOrLang.lang, element)) {
			// setSelectedElement(
			//   deleteSelectedElement(
			//     selectedCategoryOrLang.lang, element)
			// );
			dispatch(
				setCategoryOrLang({
					...selectedCategoryOrLang,
					lang: deleteSelectedElement(selectedCategoryOrLang.lang, element),
				})
			)
			// dispatch(
			//   setCategoryOrLang({ ...selectedCategoryOrLang, lang: ["Не выбрано"] })
			// );
			setSelectedElementIndex(null)

			setLastElement(
				deleteSelectedElement(selectedCategoryOrLang.lang, element).at(-1)
			)
			console.log('VVVVVVVVVVVVV', [...selectedCategoryOrLang.lang].length)
			//need to fix
		} else {
			const ff = selectedCategoryOrLang.lang.filter(element => {
				if (element !== 'Не выбрано' || element === '') {
					return element
				}
			})
			setSelectedElement([...ff, element])

			setSelectedElementIndex(index)
			setLastElement(element)
			dispatch(
				setFilters({ ...filters, language: [...filters.language, element] })
			)
			dispatch(
				setCategoryOrLang({
					...selectedCategoryOrLang,
					lang: [...ff, element],
				})
			)
		}
		handleClickedHidden()
	}

	const handleClickedHidden = () => {
		if (clickedHidden === false) {
			fetchLanguage()
		}
		setClickedHidden(!clickedHidden)
		countWrapperBlocks(selectedCategoryOrLang.lang)
		//make in this method state
	}

	const handleReload = () => {
		setSelectedElement(['Не выбрано'])
		setSelectedElementIndex(null)
		setIsMore(false)
		dispatch(setCategoryOrLang({ ...selectedCategoryOrLang, lang: [''] }))
		setClicked(false)
	}
	const [inputData, setInputData] = useState(null)
	const handleOnChange = e => {
		setInputData(e.target.value)

		const ff = selectedCategoryOrLang.lang.filter(element => {
			if (element !== 'Не выбрано' || element === '') {
				return element
			}
		})

		if (e.target.value === 'Не выбрано') {
			fetchLanguage()
		} else if (e.target.value === '') {
			fetchLanguage()
		} else {
			const filteredArgs = args.filter(arg =>
				arg.toLowerCase().includes(e.target.value.toLowerCase())
			)
			setArgs(filteredArgs)
		}
	}

	const countWrapperBlocks = arr => {
		let count = 0
		let isCount = false
		arr.map(element => {
			if (element !== 'Не выбрано') {
				count++
			}
		})
		if (count >= 1) {
			setIsMore(true)
		}
		return isCount
	}

	return (
		<div className={style.wrapperRootDropDown}>
			<div
				ref={catMenu}
				className={
					selectedCategoryOrLang.lang !== 'Не выбрано'
						? style.wrapperDropDown
						: style.wrapperDropDownRed
				}
			>
				<section
					className={
						isMore === false
							? style.btnSort
							: selectedCategoryOrLang.lang == 'Не выбрано'
							? style.btnSort
							: style.btnSortTrue
					}
					onClick={() => handleClickedHidden()}
				>
					{selectedCategoryOrLang.lang.map(element => (
						<article className={element !== 'Не выбрано' && style.wrapperBlock}>
							{element}
						</article>
					))}
				</section>
				<div className={style.wrapperPolygon}>
					<div className={style.wrapperBlockFlex}>
						<div className={style.block}></div>
						<div className={style.block}></div>
						<div className={style.block}></div>
						<div className={style.block}></div>
					</div>
					<div
						className={
							clickedHidden
								? `${style.polygonDropDown}`
								: `${style.polygonDropDownRotate}`
						}
					></div>
				</div>

				{clickedHidden && (
					<ul>
						<li className={style.firstLi}>
							<div className={style.wrapperInput}>
								<img
									className={style.img_search}
									src={iconSearch}
									alt='search'
								/>
								<input
									onChange={handleOnChange}
									value={inputData}
									type='text'
									placeholder='Поиск'
								/>
							</div>
						</li>
						{args.map((element, index) => (
							<li
								key={index}
								ref={el => (refsLi.current[index] = el)}
								onClick={() => {
									handleClickedElement(element, index)
								}}
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
								style={{
									backgroundColor:
										findSelectedElement(selectedCategoryOrLang.lang, element) &&
										lastElement === element
											? '#00a5e9'
											: 'transparent',
									color:
										findSelectedElement(selectedCategoryOrLang.lang, element) &&
										lastElement === element
											? '#fff'
											: '#99a8b2',
								}}
							>
								{findSelectedElement(selectedCategoryOrLang.lang, element) && (
									<div
										className={
											lastElement === element
												? style.wrapperDoneIcon
												: style.wrapperDoneIconBLUE
										}
									>
										<img src={lastElement === element ? done : done2} />
									</div>
								)}
								<div> {element}</div>
							</li>
						))}
					</ul>
				)}
			</div>
			{/* <button className={style.reload_btn} onClick={handleReload}>
				<img src={icoReload} alt='iconReload' className={style.imgIcon} />
			</button> */}
		</div>
	)
}
export default DropDownLang
