import React from "react"
import ContentLoader from "react-content-loader"
import {useSelector} from 'react-redux'

const LoaderGridChannels = (props) => {
  const {theme} = useSelector(state => state.theme)
  return (
    <ContentLoader 
      speed={2}
      width={1410}
      height={1310}
      viewBox="0 0 88.125rem 81.875rem"
      backgroundColor={theme?'#f3f3f3':"#404040"}
      foregroundColor={theme ?'#ffffff': '#646464'}
      {...props}
    >
      <rect x="0rem" y=".9375rem" rx=".9375rem" ry=".9375rem" width="15.5rem" height="25.125rem" />
      <rect x="16.75rem" y=".9375rem" rx=".9375rem" ry=".9375rem" width="15.5rem" height="25.125rem" />
      <rect x="33.5rem" y=".9375rem" rx=".9375rem" ry=".9375rem" width="15.5rem" height="25.125rem" />
      <rect x="50.25rem" y=".9375rem" rx=".9375rem" ry=".9375rem" width="15.5rem" height="25.125rem" />
    </ContentLoader>
  )
}
export default LoaderGridChannels

