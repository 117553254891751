export const find24Price = data => {
	let findedElement = {}

	data.map(element => {
		if (element.time === 24 && element.hot === false) {
			findedElement = element
			return element
		}
	})

	return Number(findedElement.price)
}
