/** @format */

import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Arrow from "../../assets/icons/dot_arr.png"
import { fetchUserChannel } from "../../redux/reducers/FetchChannelsSlice"
import { setCatalogArray } from "../../redux/reducers/SelectedCatalogArrayProfileSlice.js"
import LoaderPanel from "../Skeleton/SkeletonPanel"
import AddMyChannelBtn from "./AddMyChannelBtn/AddMyChannelBtn"
import { Editor } from "./Editor/Editor.jsx"
import style from "./Profile.module.scss"
import ProfileInner from "./ProfileInner/ProfileInner.jsx"
const Profile = () => {
  const { id } = useParams();
  console.log("params", id);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [logined, setLogined] = useState(false);
  // useEffect(() => {
  // 	dispatch(fetchUserAuth())
  // }, [])
  const { isLogin } = useSelector((state) => state.loginTgData);

  const { userAuth, statusAuth } = useSelector((state) => state.userDataAuth);
  const { channelsOwner, statusChannel } = useSelector(
    (state) => state.fetchChannelUser
  );

  // const { langAndCategory } = useSelector((state) => state.categoryOrLangData);
  // const location = useLocation();

  useEffect(() => {
    if (statusAuth === "success") {
      console.log("it a work");
      //	dispatch(setFetchDataChannel([]))
      dispatch(fetchUserChannel());
      //dispatch(fetchDataChannel(some))
    }
  }, [statusAuth, dispatch]);

  useEffect(() => {
    setLogined(!logined);
  }, [isLogin, logined]);

  useEffect(() => {
    const newArrr = [];
    if (statusChannel === "success") {
      channelsOwner.allChannelsAndPrice.forEach((element) => {
        newArrr.push({
          id: element.id,
          lang: element.language,
          category: element.Category,
        });
        // dispatch(setCatalogArray([{category: element.Category, id:element.id, lang: element.language}]))
      });
    }
    dispatch(setCatalogArray(newArrr));
  }, [statusChannel, channelsOwner.allChannelsAndPrice, dispatch]);

  // const data = 1; // CHANGE

  return (
    <div className={style.wrapperMyChannel}>
      <div className={style.myChannelContent}>
        <div className={style.leftPanel}>
          <div className={style.btnAddChannel}>
            <AddMyChannelBtn />
          </div>
          <div className={style.block}>
            <Link
              to="/profile/settings"
              className={
                id === "settings" ? style.btnBlockGreen : style.btnBlock
              }
            >
              {t("profile")}
            </Link>
            <button className={style.btnBlock}>
              <span className={style.contentBlock}>{t("balance")}</span>
              <span className={style.blocked}>{t("inDevelop")}</span>
            </button>
            <button className={style.btnBlock}>
              <span className={style.contentBlock}>{t("favorites")}</span>
              <span className={style.blocked}>{t("inDevelop")}</span>
            </button>
          </div>

          <span className={style.titleBlock}>{t("buyAdver")}</span>
          <div className={style.block}>
            <button className={style.btnBlock}>
              <span className={style.contentBlock}>{t("cart")}</span>
              <span className={style.blocked}>{t("inDevelop")}</span>
            </button>
            <button className={style.btnBlock}>
              <span className={style.contentBlock}>{t("myAd")}</span>
              <span className={style.blocked}>{t("inDevelop")}</span>
            </button>
            <button className={style.btnBlock}>
              <span className={style.contentBlock}>{t("myProjects")}</span>
              <span className={style.blocked}>{t("inDevelop")}</span>
            </button>
          </div>

          <span className={style.titleBlock}>{t("sellAdvertising")}</span>
          <div className={style.block}>
            <Link
              to="/profile/channels"
              className={
                id === "channels" ? style.btnBlockGreen : style.btnBlock
              }
            >
              <span className={style.contentBlock}>{t("myChannels")}</span>
              {/* <span className={style.blocked}>{t('inDevelop')}</span> */}
            </Link>
            <button className={style.btnBlock}>
              <span className={style.contentBlock}>
                {t("advertisingRequests")}
              </span>
              <span className={style.blocked}>{t("inDevelop")}</span>
            </button>
          </div>

          {/* <div className={style.hrLine}></div> */}
          {/* Только для юзера firstjedi */}
          {statusAuth === "success" && userAuth.user_name === "firstjedi" ? (
            <>
              <span className={style.titleBlock}>{t("rule")}</span>
              <div className={style.block}>
                <button className={style.btnBlock}>
                  <span className={style.contentBlock}>{t("users")}</span>
                  <span className={style.blocked}>{t("inDevelop")}</span>
                </button>
                <button className={style.btnBlock}>
                  <span className={style.contentBlock}>
                    {t("moderateChannels")}
                  </span>
                  <span className={style.blocked}>{t("inDevelop")}</span>
                </button>
                <button className={style.btnBlock}>
                  <span className={style.contentBlock}>
                    {t("catalogSettings")}
                  </span>
                  <span className={style.blocked}>{t("inDevelop")}</span>
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {
          <div>
            {/* Title page */}
            <div
              className={
                id === "settings"
                  ? style.titlePage
                  : id === "channels"
                  ? style.titlePageGreen
                  : ""
              }
            >
              {id === "settings"
                ? `${t("profile")}`
                : id === "channels"
                ? `${t("myChannels")}`
                : ""}
            </div>
            {/* {statusChannel === "loading" ? (
              <LoaderPanel />
            ) :  */}
            {statusChannel === "success" &&
            id === "channels" &&
            channelsOwner.allChannelsAndPrice.length !== 0 ? (
              channelsOwner.allChannelsAndPrice.map((element) => {
                return <Editor data={element} />;
              })
            ) : statusChannel === "success" && id === "settings" ? (
              <ProfileInner />
            ) : statusChannel === "loading" ? (
              <LoaderPanel />
            ) : channelsOwner.allChannelsAndPrice.length === 0 ? (
              <div className={style.wrapperArrow}>
                <img src={Arrow} alt="arrow" className={style.imgArrow} />
                <span className={style.textBlock}>{t("noChannel")}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default Profile;
