import iconTgStats from '../../../../assets/icon2/logo.png'
import style from '../../../GridChannels/[GridDetailChannels]/ButtonLinkTgStat/ButtonLinkTgStat.module.scss'

const ButtonLink = ({ link_Cannel }) => {
	const some_need_delete = 'https://t.me/'
	let sometext = link_Cannel.replace(some_need_delete, '')
	const link_TgStat = link_Cannel
	const handleClick = async key => {
		try {
			if (key === link_TgStat) {
				await navigator.clipboard.writeText(link_TgStat)
			}
		} catch (error) {
			console.log('не удалось скопировать ссылку')
		}
	}

	return (
		<div className={style.blockStatistic}>
			<button
				onClick={() => handleClick(link_TgStat)}
				className={style.btnStat}
			>
				{' '}
				{/* передача key в handleClick */}
				<img className={style.TgStatImg} src={iconTgStats} alt='tgStat' />
				TGSTAT.RU
			</button>
		</div>
	)
}

export default ButtonLink
