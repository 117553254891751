/** @format */

import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import favorite1 from '../../../assets/favorite.svg'
import favorited from '../../../assets/favorited.svg'
import ton from '../../../assets/icons/ton.svg'
import userIcon from '../../../assets/person.svg'
import { fetchFavoritePut } from '../../../redux/reducers/FavoriteSlicePut'
import { fetchUserAuthForLike } from '../../../redux/reducers/UserSliceAuthForLike'
import { sortedPriceObjects } from '../Sorting/Sorting'
import style from './ChannelComponent.module.scss'
const ChannelComponent = ({ element }) => {
	const { t } = useTranslation()
	function trimTelegramLink(link) {
		if (link.startsWith('https://t.me/')) {
			return link.slice(13)
		}
		return link
	}

	const [link, setLink] = useState(null)
	const [ownerName, setOwnerName] = useState(null)
	const { isTon } = useSelector(state => state.HandleTonSlice)
	const findUserName = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_KEY}/auth/user_name`,
				{
					params: {
						User_id: Number(element.User_id),
					},
				}
			)
			setOwnerName('https://t.me/' + data.user_name)
			return data
		} catch (error) {
			return error
		}
	}
	useEffect(() => {
		findUserName()

		const updaedLink = trimTelegramLink(element.link_Cannel)
		setLink(updaedLink)
	}, [])
	const dispatch = useDispatch()
	const { userAuthLike, statusAuthLike } = useSelector(
		state => state.fetchUserLike
	)
	const { statusDelete, statusFavorite, favoritePut } = useSelector(
		state => state.fetchFavoritePutLike
	)

	const [clicked, setClicked] = useState(false)
	const [indexY, setIndexY] = useState(0)
	const [clickedTypePrice, setClickedTypePrice] = useState({
		24: false,
		48: false,
		72: false,
		0: false,
		1: false,
		2: false,
	})
	useEffect(() => {
		dispatch(fetchUserAuthForLike())
	}, [clicked])

	const handleClickedTypePrice = (key, index) => {
		setClickedTypePrice(prevState => {
			setIndexY(index)
			// Сначала создаем новый объект состояния, где все ключи устанавливаются в false
			let newState = {
				24: false,
				48: false,
				72: false,
				0: false,
				1: false,
				2: false,
			}
			// Устанавливаем значение true только для кнопки, на которую был сделан клик
			newState[key] = true
			return newState
		})
	}

	function truncateText(text, maxLength) {
		if (text === null || text === '') {
			return ''
		}
		if (text.length <= maxLength) {
			return text
		}
		const truncatedText = text.substring(0, maxLength).trim()
		return truncatedText + '...'
	}

	const handleClickedFavorite = () => {
		setClicked(!clicked)

		if (statusAuthLike === 'success') {
			const obj = {
				user_id: userAuthLike.User_id,
				channel_id: parseInt(element.id),
				statusDelete,
			}
			dispatch(fetchFavoritePut(obj))
		} else {
			alert(`${t('needAuth')}`)
		}
	}
	useEffect(() => {
		const putCount = async () => {
			try {
				const token = Cookies.get('token')
			} catch (error) {
				console.log(error)
			}
		}
		putCount()
	}, [statusFavorite, statusDelete])

	const ConvertIntToRUPercent = among => {
		return new Intl.NumberFormat('ru', { style: 'percent' }).format(among)
	}
	const ConvertIntToRUNumberFormat = among => {
		return new Intl.NumberFormat('ru', { style: 'decimal' }).format(among)
	}
	const tokenAuth = Cookies.get('token')
	useEffect(() => {
		sortedPriceObjects(element.priceObjects).map((time, index) => {
			if (time.time === 24) {
				handleClickedTypePrice(time.time, index)
			}
		})
	}, [element])

	return (
		<div className={style.wrapperChannelComponent}>
			<div className={style.wrapperComponent}>
				<div className={style.wrapperImg}>
					{statusAuthLike === 'loading' && (
						<TailSpin
							visible={true}
							height='1.088rem'
							width='1.088rem'
							color='#00A5E9'
							ariaLabel='tail-spin-loading'
							radius='0.025rem'
							wrapperStyle={{
								position: 'absolute',
								left: '91%',
								top: '10%',
								backgroundColor: '#fff',
								borderRadius: '0.3rem',
							}}
							wrapperClass=''
						/>
					)}
					{statusAuthLike === 'success' && element.selected === true && (
						<button
							onClick={handleClickedFavorite}
							className={style.wrapperFavorite}
						>
							<img
								className={style.wrapperFavoriteImg}
								src={favorited}
								alt='liked'
							/>
						</button>
					)}
					{statusAuthLike === 'success' && element.selected === false && (
						<button
							onClick={handleClickedFavorite}
							className={style.wrapperFavorite}
						>
							<img
								className={style.wrapperFavoriteImg}
								src={favorite1}
								alt='dislike'
							/>
						</button>
					)}
					<div>
						<img
							className={style.borderRadius}
							src={`${process.env.REACT_APP_API_KEY}${element.url_background_channel}`}
							alt={`${element.url_background_channel}`}
						/>
					</div>

					<div className={style.wrapperImgAndDesc}>
						<Link to={`/channels/${link}`}>
							<img
								src={`${process.env.REACT_APP_API_KEY}/bot/${element.url_Image_Channel}`}
								alt='logo'
							/>
						</Link>

						<div className={style.wrapperBLOCK}>
							<div className={style.wrapperCategoryAndPosition}>
								<span className={style.bordedElement}>{element.Category}</span>
								{/* <span className={style.bordedElement}>#{element.position}</span> */}
							</div>
							<h2 className={style.wrapperNameAndPosition}>
								<Link className={style.linkStyle} to={`/channels/${link}`}>
									{element.name_channel}
								</Link>
							</h2>
						</div>
					</div>
				</div>

				<div className={style.wrapperDescChannel}>
					<div className={style.wrapperQuotes}>
						<div className={style.borderLeft}></div>
						<span className={style.quotesStyle}>
							{element.desc_is_custom === false
								? truncateText(element.desc_channel, 100)
								: truncateText(element.channel_desc_custom, 100)}
						</span>
					</div>
					<div className={style.wrapperStatistics}>
						<div className={style.pairStatistics}>
							<div className={style.wrapperOneStatistics}>
								<div>
									<img
										className={style.wrapperImageIcon}
										src={userIcon}
										alt=''
									/>
								</div>
								<span>
									<strong>
										{ConvertIntToRUNumberFormat(element.count_subscribers)}{' '}
									</strong>
									{t('subsChannelComp')}
								</span>
							</div>
							<div>
								<img className={style.wrapperImageIcon} src={userIcon} alt='' />
								<span>
									<strong>{ConvertIntToRUNumberFormat(element.views)}</strong>{' '}
									{t('viewsPost')}
								</span>
							</div>
						</div>
						<div className={style.pairStatistics2}>
							<div>
								{/* <img className={style.wrapperImageIcon} src={priceCMP} alt="" /> */}
								<span>
									CPM <strong>{ConvertIntToRUNumberFormat(element.CPM)}</strong>
								</span>
							</div>
							{/* <div>
                <img className={style.wrapperImageIcon} src={price} alt="" />
                <span>
                  <strong>{ConvertIntToRUPercent(element.ERR)}</strong> ERR
                </span>
              </div> */}
						</div>
					</div>
				</div>
				{/* <div className={style.forFutureFeatures}>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
				{/* <hr color="#e1e5e8" /> */}
				<div className={style.wrapperDefaultPrice}>
					<span>
						{isTon === false ? (
							Number(
								sortedPriceObjects(element.priceObjects)[indexY].price
							).toLocaleString('ru-RU', {
								style: 'currency',
								currency: 'RUB',
								minimumFractionDigits: 0,
							})
						) : (
							<>
								{Number(
									sortedPriceObjects(element.priceObjects)[indexY].price_token
								)}
								<img src={ton} alt='TON' />
							</>
						)}
					</span>
				</div>
			</div>
			<div className={style.priceType}>
				{sortedPriceObjects(element.priceObjects).map(
					(time, index) =>
						time.hot === false && (
							<button
								key={index}
								className={
									clickedTypePrice[time.time]
										? style.clickedBtn
										: style.nonClickedButton
								}
								onClick={() => handleClickedTypePrice(time.time, index)}
							>
								{time.time === 24 && '1/24'}
								{time.time === 48 && '1/48'}
								{time.time === 72 && '1/72'}
								{time.time === 0 && `${t('native')}`}
								{time.time === 1 && `${t('repost')}`}
								{time.time === 2 && `${t('withoutRemove')}`}
								{time.hot_date}
							</button>
						)
				)}
			</div>
			<a
				target='_blank'
				href={`${ownerName}`}
				className={style.BottomBuy}
				rel='noreferrer'
			>
				<button className={style.BtnBuy}>{t('buy')}</button>
			</a>
			{/* <button
        onClick={() => {
          dispatch(fetchSubsPut(element.id));
        }}
        className={style.BtnFollowing}
      >
        <span>
          {element.is_sub === true
            ? `${t("unsubscribeHot")}`
            : `${t("subscribeHot")}`}
        </span>
      </button> */}
		</div>
	)
}

export default ChannelComponent
