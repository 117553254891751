import debounce from 'lodash.debounce'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import iconSearch from '../../assets/search.svg'
import { fetchDataChannel } from '../../redux/reducers/ChannelDataSlice'
import style from './Search.module.scss'

function Search() {
	const { t } = useTranslation()
	const { sort } = useSelector(state => state.sortData)
	const dispatch = useDispatch()
	const [valueLocal, setValueLocal] = useState('')
	const inputRef = React.useRef()

	const searchEvent = event => {
		testDebounce(event)
		setValueLocal(event.target.value)
	}

	const testDebounce = useCallback(
		debounce(event => {
			const some = {
				all_published: true,
				name_channel: event.target.value,
				skip: null,
				take: null,
				is_search: true,
				sort,
			}
			dispatch(fetchDataChannel(some))
		}, 550),
		[sort] // Добавьте sort как зависимость
	)

	return (
		<div className={style.search}>
			<img className={style.img_search} src={iconSearch} alt='search' />
			<input
				ref={inputRef}
				value={valueLocal}
				onChange={searchEvent} // Используйте правильное название функции
				type='text'
				placeholder={t('search')}
			/>
		</div>
	)
}

export default Search
