import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './LinkChannel.module.scss'

const LinkChannel = ({ url }) => {
	const { t } = useTranslation()
	const hoverRef = useRef(null)
	const [hovered, setHovered] = useState(false)

	const handleMouseEnter = () => setHovered(true)
	const handleMouseLeave = () => setHovered(false)

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			ref={hoverRef}
			className={style.wrapperLinkChannel}
		>
			<a target='_blank' href={url}>
				<span className={hovered ? style.hiddenText : style.visibleText}>
					{url}
				</span>
				<span className={hovered ? style.visibleText : style.hiddenText}>
					{t('openTg')}
				</span>
			</a>
		</div>
	)
}

export default LinkChannel
