import React from 'react'
import ContentLoader from 'react-content-loader'
import { useSelector } from 'react-redux'

const LoaderPanel = props => {
	const { theme } = useSelector(state => state.theme)
	return (
		<ContentLoader
			speed={2}
			width={1360}
			height={600}
			viewBox='0 0 68rem 33rem'
			backgroundColor={theme ? '#f3f3f3' : '#404040'}
			foregroundColor={theme ? '#ffffff' : '#646464'}
			{...props}
		>
			<rect
				x='1.2rem'
				y='1.25rem'
				rx='.9375rem'
				ry='.9375rem'
				width='66rem'
				height='25rem'
			/>
		</ContentLoader>
	)
}
export default LoaderPanel
