import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	filters: {
		category: ['Не выбрано'],
		language: ['Не выбрано'],
		price: { min: 0, max: 0 },
		cpm: { min: 0, max: 0 },
		subscribers: { min: 0, max: 0 },
		views: { min: 0, max: 0 },
		err: { min: 0, max: 0 },
		selected: false,
		admin_online: false,
		opened_channel: false,
		verify: false,
		checked_channel: false,
		ton: false,
	},
}

const FilterChannelSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		setFilters(state, action) {
			state.filters = action.payload
		},
		deleteFilterChannel(state) {
			state.filters = initialState
		},
	},
})
export const { setFilters, deleteFilterChannel } = FilterChannelSlice.actions

export default FilterChannelSlice.reducer
