import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isLogin: null,
}
const IsLoginedSlice = createSlice({
	name: 'loginedTgSlice',
	initialState,
	reducers: {
		setIsLoginedSlice(state, action) {
			state.isLogin = action.payload
		},
	},
})
export const { setIsLoginedSlice } = IsLoginedSlice.actions
export default IsLoginedSlice.reducer
