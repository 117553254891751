import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './Button.module.scss'
const Button = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<button
			onClick={() => {
				navigate(-1)
			}}
			className={style.wrapperButton}
		>
			{t('backToCatalog')}
		</button>
	)
}

export default Button
