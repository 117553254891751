/** @format */

import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import done from '../../assets/done.svg'
import done2 from '../../assets/done2.svg'
import iconSearch from '../../assets/search.svg'
import { fetchCategotiesAsyncThunk } from '../../redux/reducers/CategoriesSlice'
import { setFilters } from '../../redux/reducers/FilterChannelSlice'
import { setCategoryOrLang } from '../../redux/reducers/SelectedCategoriesSlice'
import style from '../DropDownLang/DropDownFilt.module.scss'
import styles from './DropDownFilt.module.scss'
const DropDown = () => {
	// const [clicked, setClicked] = useState(false);
	const [isMore, setIsMore] = useState(false)
	const [lastElement, setLastElement] = useState(null)
	const [clickedHidden, setClickedHidden] = useState(false)
	// const [categoriesTwo, setCategotiesTwo] = useState([]);
	const [skip, setSkip] = useState(9)
	// const [take, setTake] = useState(9);
	const dispatch = useDispatch()
	const refsLi = useRef([])
	// const [changed, setChanged] = useState(false);
	let catMenu = useRef()
	const refDropDownTop = useRef(null)
	// const [selectedElement, setSelectedElement] = useState(["Не выбрано"]);
	// dispatch(
	//   setCategoryOrLang({ ...selectedCategoryOrLang, category: ["Не выбрано"] })
	// );

	// const [selectedElementIndex, setSelectedElementIndex] = useState(null);
	const { filters } = useSelector(state => state.filterChannel)
	const { selectedCategoryOrLang } = useSelector(
		state => state.selectedCategoryOrLangData
	)
	useEffect(() => {
		const handler = e => {
			if (catMenu.current && !catMenu.current.contains(e.target)) {
				setClickedHidden(false)
			}
		}

		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	}, [])
	// useEffect(() => {
	//   setSelectedElement(["Не выбрано"]);
	// }, []);
	const findSelectedElement = (arr, element) => {
		let isFind = false
		console.log('arr876876876', arr)
		console.log('ele78768768', element)
		arr.forEach(el => {
			if (el === element) {
				isFind = true
			}
		})
		return isFind
	}
	const deleteSelectedElement = (arr, element) => {
		let result = []
		result = arr.filter(el => el !== element)
		if (result.length === 0) {
			result = ['Не выбрано']
		}
		return result
	}
	const handleClickedElement = (element, index) => {
		// setClicked(true);
		if (findSelectedElement(selectedCategoryOrLang.category, element)) {
			// dispatch(
			//   setCategoryOrLang(
			//     deleteSelectedElement(selectedCategoryOrLang.category, element)
			//   )
			// );
			dispatch(
				setCategoryOrLang({
					...selectedCategoryOrLang,
					category: deleteSelectedElement(
						selectedCategoryOrLang.category,
						element
					),
				})
			)
			dispatch(
				setFilters({ ...filters, category: [...filters.category, element] })
			)
			// setSelectedElementIndex(null);
			setLastElement(
				deleteSelectedElement(selectedCategoryOrLang.category, element).at(-1)
			)
			// setClicked(false);
			setIsMore(false)
		} else {
			const ff = selectedCategoryOrLang.category.filter(
				element => element !== 'Не выбрано'
			)
			// setSelectedElement([...ff, element]);
			dispatch(
				setFilters({ ...filters, category: [...filters.category, element] })
			)
			// setSelectedElementIndex(index);
			setLastElement(element)
			dispatch(
				setCategoryOrLang({
					...selectedCategoryOrLang,
					category: [...ff, element],
				})
			)
		}
		handleClickedHidden()
	}

	const handleClickedHidden = () => {
		setClickedHidden(!clickedHidden)
		countWrapperBlocks(selectedCategoryOrLang.category)
		console.log('categories', categories)
		//categories
	}
	// useEffect(()=>{
	//   console.log("useffect")
	//        dispatch(fetchCategotiesAsyncThunk(skip))
	// },[])
	const handleOnScroll = e => {
		e.preventDefault()
		if (categories.length === 45) {
			return
		}

		if (refDropDownTop.current !== null) {
			console.log('it a scroll2')
			console.log(
				'refDropDownTop.current.clientHeight',
				refDropDownTop.current.clientHeight
			)
			console.log(
				'refDropDownTop.current.scrollTop',
				refDropDownTop.current.scrollTop
			)
			console.log(
				'refDropDownTop.current.scrollHeight',
				refDropDownTop.current.scrollHeight
			)
			if (
				refDropDownTop.current.clientHeight +
					refDropDownTop.current.scrollTop >=
				refDropDownTop.current.scrollHeight
			) {
				console.log('it a scroll')
				setSkip(skip + 9)
				dispatch(fetchCategotiesAsyncThunk({ one: false, skip }))
			}
		}
	}
	const [inputData, setInputData] = useState(null)

	// const handleReload = () => {
	//   dispatch(setCategories([]));
	//   setIsMore(false);
	//   setSelectedElement(["Не выбрано"]);
	//   dispatch(
	//     setCategoryOrLang({ ...selectedCategoryOrLang, category: ["Не выбрано"] })
	//   );
	//   setSelectedElementIndex(null);
	//   setClicked(false);
	//   dispatch(fetchCategotiesAsyncThunk({ one: false, skip: 0 }));

	//   setSkip(9);
	// };
	const { categories } = useSelector(state => state.CategoriesData)

	const handleOnChange = e => {
		setInputData(e.target.value)
		// const ff = selectedCategoryOrLang.category.filter(element => element !== "Не выбрано");
		//setSelectedElement([...ff, e.target.value])

		if (e.target.value === 'Не выбрано') {
			setTimeout(() => {
				dispatch(
					fetchCategotiesAsyncThunk({
						categories_name: e.target.value,
						one: false,
						skip,
					})
				)
			}, 900)
		} else {
			setTimeout(() => {
				dispatch(
					fetchCategotiesAsyncThunk({
						categories_name: e.target.value,
						one: true,
					})
				)
			}, 900)
		}
	}
	const countWrapperBlocks = arr => {
		let count = 0
		let isCount = false
		arr.forEach(element => {
			if (element !== 'Не выбрано') {
				count++
			}
		})
		if (count >= 1) {
			setIsMore(true)
		}
		return isCount
	}
	console.log('g ', selectedCategoryOrLang.category)
	return (
		<div className={style.wrapperRootDropDown}>
			<div ref={catMenu} className={style.wrapperDropDown}>
				<section
					className={
						isMore === false
							? style.btnSort
							: selectedCategoryOrLang.category == 'Не выбрано'
							? style.btnSort
							: style.btnSortTrue
					}
					onClick={() => handleClickedHidden()}
				>
					{selectedCategoryOrLang.category.map(element => (
						<article className={element !== 'Не выбрано' && style.wrapperBlock}>
							{element}
						</article>
					))}
				</section>
				<div className={styles.wrapperPolygon}>
					<div className={styles.wrapperBlockFlex}>
						<div className={styles.block}></div>
						<div className={styles.block}></div>
						<div className={styles.block}></div>
						<div className={styles.block}></div>
					</div>
					<div
						className={
							clickedHidden
								? `${styles.polygonDropDown}`
								: `${styles.polygonDropDownRotate}`
						}
					></div>
				</div>
				{clickedHidden && categories.length > 0 && (
					<ul onScroll={handleOnScroll} ref={refDropDownTop}>
						<li className={style.firstLi}>
							<div className={style.wrapperInput}>
								<img
									className={style.img_search}
									src={iconSearch}
									alt='search'
								/>
								<input
									onChange={handleOnChange}
									value={inputData}
									type='text'
									placeholder='Поиск'
								/>
							</div>
						</li>
						{categories.map((element, index) => (
							<li
								key={index}
								ref={el => (refsLi.current[index] = el)}
								onClick={() => {
									handleClickedElement(element.categories_name, index)
								}}
								style={{
									backgroundColor:
										findSelectedElement(
											selectedCategoryOrLang.category,
											element.categories_name
										) && lastElement === element.categories_name
											? '#00a5e9'
											: 'transparent',
									color:
										findSelectedElement(
											selectedCategoryOrLang.category,
											element.categories_name
										) && lastElement === element.categories_name
											? '#fff'
											: '#99a8b2',
								}}
							>
								{findSelectedElement(
									selectedCategoryOrLang.category,
									element.categories_name
								) && (
									<div
										className={
											lastElement === element.categories_name
												? style.wrapperDoneIcon
												: style.wrapperDoneIconBLUE
										}
									>
										<img
											src={
												lastElement === element.categories_name ? done : done2
											}
											alt='Описание изображения'
										/>
									</div>
								)}
								<div className={style.wrapperNameCategories}>
									{' '}
									{element.categories_name}
								</div>
							</li>
						))}
					</ul>
				)}
			</div>
			{/* <button className={style.reload_btn} onClick={handleReload}>
        <img src={icoReload} alt='iconReload' className={style.imgIcon} />
      </button> */}
		</div>
	)
}

export default DropDown
