/** @format */

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import favorite1 from '../../../assets/favorite.svg'
import favorited from '../../../assets/favorited.svg'
import timerIcon from '../../../assets/icons/timer.svg'
import ton from '../../../assets/icons/ton.svg'
import userIcon from '../../../assets/person.svg'
import { fetchFavoritePut } from '../../../redux/reducers/FavoriteSlicePut'
import { fetchUserAuthForLike } from '../../../redux/reducers/UserSliceAuthForLike'
import style from './ChannelComponentHot.module.scss'
const ChannelComponentHot = ({ element }) => {
	const { t } = useTranslation()
	function trimTelegramLink(link) {
		if (link.startsWith('https://t.me/')) {
			return link.slice(13)
		}
		return link
	}

	const [link, setLink] = useState(null)
	const [ownerName, setOwnerName] = useState(null)
	const { isTon } = useSelector(state => state.HandleTonSlice)
	const findUserName = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_KEY}/auth/user_name`,
				{
					params: {
						User_id: Number(element.User_id),
					},
				}
			)
			setOwnerName('https://t.me/' + data.user_name)
			return data
		} catch (error) {
			return error
		}
	}
	useEffect(() => {
		findUserName()

		const updaedLink = trimTelegramLink(element.link_Cannel)
		setLink(updaedLink)
	}, [])
	const [clicked, setClicked] = useState(false)
	const [timer, setTimer] = useState(0)
	const [counter, setCounter] = useState(false)
	const [clickedTypePrice, setClickedTypePrice] = useState({
		24: false,
		48: false,
		72: false,
		0: false,
		1: false,
		2: false,
	})
	const dispatch = useDispatch()
	const firstDifference = data => {
		const current = new Date()
		const date2 = new Date(data)
		const timeDifference = Math.floor(
			(date2.getTime() - current.getTime()) / 1000
		)

		setTimer(timeDifference)
	}
	const checkForHot = () => {
		element.priceObjects.filter(element => {
			if (element.for_hot === true) {
				setCounter(true)
			}
		})
	}
	useEffect(() => {
		dispatch(fetchUserAuthForLike())
	}, [clicked])
	useEffect(() => {
		firstDifference(toFindHotPriceDate(element))
		checkForHot()
	}, [])
	const { userAuthLike, statusAuthLike } = useSelector(
		state => state.fetchUserLike
	)

	const getForHot = () => {
		return element.priceObjects.filter(element => {
			return element.for_hot === true
		})
	}
	const getHot = () => {
		return element.priceObjects.filter(element => {
			return element.hot === true
		})
	}
	const ConvertIntToRUPercent = among => {
		return new Intl.NumberFormat('ru', { style: 'percent' }).format(among)
	}
	const formulaDiscount = () => {
		const a = getForHot()[0].price
		const b = getHot()[0].price
		const d = a - b
		const x = (d * 100) / a
		return Math.round(x)
	}
	const formulaDiscountTon = () => {
		const a = getForHot()[0].price_token
		const b = getHot()[0].price_token
		const d = a - b
		const x = (d * 100) / a
		return Math.round(x)
	}

	const handleClickedTypePrice = key => {
		setClickedTypePrice(prevState => {
			// Сначала создаем новый объект состояния, где все ключи устанавливаются в false
			let newState = {
				24: false,
				48: false,
				72: false,
				0: false,
				1: false,
				2: false,
			}
			// Устанавливаем значение true только для кнопки, на которую был сделан клик
			newState[key] = true
			return newState
		})
	}

	//element.priceObjects[3].hot_date
	const formatData = somedate => {
		const updatedFormatData = new Date(somedate)
		return updatedFormatData
	}
	const TimeDifferenceOne = ({ hotDate }) => {
		let updatedData = formatData(hotDate)
		const [currentTime, setCurrentTime] = useState(new Date())

		useEffect(() => {
			const interval = setInterval(() => {
				setCurrentTime(new Date())
			}, 1000)

			return () => clearInterval(interval)
		}, [])

		// Проверяем, является ли время отрицательным
		const timeDifference = Math.floor(
			(updatedData.getTime() - currentTime.getTime()) / 1000
		)

		setTimer(timeDifference)
		// if (timeDifference < 0) {
		// 	return null // Если время отрицательное, не отображаем компонент
		// }

		const days = Math.floor(timeDifference / 3600 / 24)
		const hours = Math.floor(timeDifference / 3600 - days * 24)
		const minutes = Math.floor((timeDifference % 3600) / 60)
		// const seconds = Math.floor(timeDifference % 60);

		return (
			<div className={style.timer}>
				{`${days} д. ${hours} ч. ${minutes} мин.`}{' '}
				<img src={timerIcon} alt='timer' />
			</div>
		)
	}
	const handleClickedFavorite = () => {
		setClicked(!clicked)

		if (statusAuthLike === 'success') {
			const obj = {
				user_id: userAuthLike.User_id,
				channel_id: parseInt(element.id),
			}
			dispatch(fetchFavoritePut(obj))
		} else {
			alert(`${t('needAuth')}`)
		}
	}
	function truncateText(text, maxLength) {
		if (text === null || text === '') {
			return ''
		}
		if (text.length <= maxLength) {
			return text
		}
		const truncatedText = text.substring(0, maxLength).trim()
		return truncatedText + '...'
	}

	const ConvertIntToRUNumberFormat = among => {
		return new Intl.NumberFormat('ru', { style: 'decimal' }).format(among)
	}
	const filtredDefaultPrice = element.priceObjects.filter(element => {
		return element.for_hot === true
	})
	const toFindHotPriceDate = element => {
		let HotObject = {}
		element.priceObjects.forEach(element => {
			if (typeof element.hot_date == 'string') {
				HotObject = element
				return element
			}
		})

		return HotObject.hot_date
	}

	return (
		counter &&
		timer > 0 && (
			<div className={style.wrapperChannelComponent}>
				<div className={style.wrapperComponent}>
					<div className={style.wrapperImg}>
						<div className={style.TimeAndDiscount}>
							<div className={style.TimeBuy}>
								<TimeDifferenceOne hotDate={toFindHotPriceDate(element)} />
							</div>
						</div>
						{statusAuthLike === 'loading' && (
							<TailSpin
								visible={true}
								height='1.088rem'
								width='1.088rem'
								color='#00A5E9'
								ariaLabel='tail-spin-loading'
								radius='0.025rem'
								wrapperStyle={{
									position: 'absolute',
									left: '91%',
									top: '10%',
									backgroundColor: '#fff',
									borderRadius: '0.3rem',
								}}
								wrapperClass=''
							/>
						)}
						{statusAuthLike === 'success' && element.selected === true && (
							<button
								onClick={handleClickedFavorite}
								className={style.wrapperFavorite}
							>
								<img
									className={style.wrapperFavoriteImg}
									src={favorited}
									alt='liked'
								/>
							</button>
						)}
						{statusAuthLike === 'success' && element.selected === false && (
							<button
								onClick={handleClickedFavorite}
								className={style.wrapperFavorite}
							>
								<img
									className={style.wrapperFavoriteImg}
									src={favorite1}
									alt='dislike'
								/>
							</button>
						)}
						<div className={style.wrapperBackgroundImage}>
							<img
								src={`${process.env.REACT_APP_API_KEY}${element.url_background_channel}`}
								alt={`${element.url_background_channel}`}
							/>
						</div>

						<div className={style.wrapperImgAndDesc}>
							<Link to={`/channels/${link}`}>
								<img
									src={`${process.env.REACT_APP_API_KEY}/bot/${element.url_Image_Channel}`}
									alt='img channel'
								/>
							</Link>

							<div className={style.wrapperBLOCK}>
								<div className={style.wrapperCategoryAndPosition}>
									<span className={style.bordedElement}>
										{element.Category}
									</span>
									{/* <span className={style.bordedElement}>
                    #{element.position}
                  </span> */}
								</div>
								<h2 className={style.wrapperNameAndPosition}>
									<Link className={style.linkStyle} to={`/channels/${link}`}>
										{element.name_channel}
									</Link>
								</h2>
							</div>
						</div>
					</div>

					<div className={style.wrapperDescChannel}>
						<div className={style.wrapperQuotes}>
							<div className={style.borderLeft}></div>
							<span className={style.quotesStyle}>
								{element.desc_is_custom === false
									? truncateText(element.desc_channel, 30)
									: truncateText(element.channel_desc_custom, 30)}
							</span>
						</div>
						<div className={style.wrapperStatistics}>
							<div className={style.pairStatistics}>
								<div className={style.wrapperOneStatistics}>
									<img
										className={style.wrapperImageIcon}
										src={userIcon}
										alt=''
									/>
									<span>
										<strong>
											{ConvertIntToRUNumberFormat(element.count_subscribers)}{' '}
										</strong>
										{t('subsChannelComp')}
									</span>
								</div>
								<div className={style.wrapperOneStatistics}>
									<img
										className={style.wrapperImageIcon}
										src={userIcon}
										alt=''
									/>
									<span>
										<strong>{ConvertIntToRUNumberFormat(element.views)}</strong>{' '}
										{t('viewsPost')}
									</span>
								</div>
							</div>
							<div className={style.pairStatistics2}>
								<div>
									<span>
										CPM{' '}
										<strong>{ConvertIntToRUNumberFormat(element.CPM)}</strong>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className={style.Prices}>
						<div className={style.wrapperDefaultPriceUnderline}>
							<span>
								{isTon === false ? (
									Number(filtredDefaultPrice[0].price).toLocaleString('ru-RU', {
										style: 'currency',
										currency: 'RUB',
										minimumFractionDigits: 0,
									})
								) : (
									<>
										{Number(filtredDefaultPrice[0].price_token)}
										<img src={ton} alt='TON' />
									</>
								)}
							</span>
						</div>
						<div className={style.wrapperDefaultPrice}>
							<span>
								{isTon === false ? (
									element.priceObjects
										.filter(element => element.hot === true)
										.map(element => Number(element.price))
										.toLocaleString('ru-RU', {
											style: 'currency',
											currency: 'RUB',
											minimumFractionDigits: 0,
										})
								) : (
									<>
										{Number(
											element.priceObjects
												.filter(element => element.hot === true)
												.map(element => Number(element.price_token))
										)}
										<img src={ton} alt='TON' />
									</>
								)}
							</span>
						</div>
					</div>
				</div>
				<div className={style.wrapperTypePrice}>
					<div className={style.priceType}>
						{element.priceObjects.map(
							(time, index) =>
								time.for_hot === true && (
									<button
										key={index}
										className={style.clickedBtn}
										//	onClick={() => handleClickedTypePrice(time)}
									>
										<span>
											{time.time === 24 && '1/24'}
											{time.time === 48 && '1/48'}
											{time.time === 72 && '1/72'}
											{time.time === 0 && `${t('native')}`}
											{time.time === 1 && `${t('repost')}`}
											{time.time === 2 && `${t('withoutRemove')}`}
										</span>
									</button>
								)
						)}
					</div>
					<div className={style.priceType}>
						{element.priceObjects.map(
							(time, index) =>
								time.hot === true && (
									<button
										key={index}
										className={style.clickedBtn}
										onClick={() => handleClickedTypePrice(time)}
									>
										<span>
											{time.hot_date &&
												formatData(time.hot_date).toLocaleDateString('ru-RU', {
													month: 'long',
													day: 'numeric',
												})}
										</span>
									</button>
								)
						)}
					</div>
					<div className={style.priceType}>
						{element.priceObjects.map(
							(time, index) =>
								time.hot === true && (
									<button
										key={index}
										className={style.clickedBtn}
										onClick={() => handleClickedTypePrice(time)}
									>
										<span>
											{time.hot_date &&
												formatData(time.hot_date).toLocaleTimeString('ru-RU', {
													hour: 'numeric',
													minute: 'numeric',
												})}
										</span>
									</button>
								)
						)}
					</div>
					<div className={style.wrapperFormulaDis}>
						-{isTon === false ? formulaDiscount() : formulaDiscountTon()}%
					</div>
				</div>
				<a target='_blank' href={`${ownerName}`} rel='noreferrer'>
					<button className={style.BtnBuy}>{t('buyWithdiscount')}</button>
				</a>
				{/* <div className={style.TimeAndDiscount}>
          <div className={style.wrapperFormulaDis}>
            {isTon === false ? formulaDiscount() : formulaDiscountTon()}%
          </div>
          <div className={style.TimeBuy}>
            <TimeDifferenceOne hotDate={toFindHotPriceDate(element)} />
          </div>
        </div> */}
			</div>
		)
	)
}

export default ChannelComponentHot
