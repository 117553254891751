/** @format */

import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import iconTelegram from "../../assets/telegram-svgrepo-com.svg";
import { setClickLogin } from "../../redux/reducers/ClickedLoginSlice";
import { fetchUser } from "../../redux/reducers/UserSlice";
import style from "./PopupAuth.module.scss";
import { useTranslation } from "react-i18next";
const PopupAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logined } = useSelector((state) => state.loginData);
  const { token } = useSelector((state) => state.UserTokenUUIDv4Data);

  const API_BASE_URL = process.env.REACT_APP_API_KEY;
  const [telegramId, setTelegramId] = useState(null);
  const [isUpdated, setIsUpdated] = useState(null);
  const [tokenServer, setTokenServe] = useState(null);
  const handlePopup = () => {
    dispatch(setClickLogin(!logined));
  };
  const someOne6 = Cookies.get("token_uuid");
  const takeTelegramId = async () => {
    const interval = setInterval(async () => {
      try {
        const someOne = Cookies.get("token_uuid");
        const { data } = await axios.get(`${API_BASE_URL}/auth/token`, {
          params: {
            token: someOne,
          },
        });
        console.log("DSDSDSSD", data);

        // if (JSON.parse(data.message.telegram_id.length) != null) {
        // 	setTelegramId(data.message.telegram_id)

        // 	setTokenServe(data.message.token)
        // 	dispatch(fetchUser(data.message.token))
        // 	dispatch(setClickLogin(!logined))
        // 	//dispatch(fetchUserAuth())
        // 	setIsUpdated(true)
        // 	clearInterval(interval)
        if (JSON.parse(data.message.telegram_id) === null) {
          console.log("fff");
        } else {
          dispatch(fetchUser(data.message.token));
          dispatch(setClickLogin(!logined));

          clearInterval(interval);
        }
      } catch (error) {
        console.log(error);
      }
    }, 2000);

    setTimeout(() => {
      clearInterval(interval);
    }, 620000); // 2 минуты = 120000 миллисекунд
  };

  return (
    <div className={style.wrapperPopupAuth}>
      <div className={style.titleBlockAuth}>
        <div>
          <span>{t("auth")}</span>
        </div>
        <button onClick={handlePopup} className={style.closeBtn}></button>
      </div>
      <div className={style.wrapperPopupLoginBtn}>
        <span>{t("pushButton")}</span>
        <a
          target="_blank"
          onClick={takeTelegramId}
          href={`${process.env.REACT_APP_LINK_BOT}?start=${someOne6}`}
        >
          <img src={iconTelegram} alt="telegram" />
          {t("sighInTg")}
        </a>
        <span className={style.boldSpan}>
          {t("descriptionAuth")}
          {t("descriptionAuth2")}
          {t("descriptionAuth3")}
        </span>
      </div>
    </div>
  );
};

export default PopupAuth;
