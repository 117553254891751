import { useTranslation } from 'react-i18next'
import iconTgStats from '../../../../assets/icon2/logo.png'
import iconTelemetr from '../../../../assets/icon2/tel.png'
import style from '../../../GridChannels/[GridDetailChannels]/ButtonLink/ButtonLink.module.scss'

const ButtonLink = ({ name_channel, public_type, link_Cannel }) => {
	const { t } = useTranslation()
	const isOpen = !public_type
	const some_need_delete = 'https://t.me/'
	let sometext = link_Cannel.replace(some_need_delete, '')
	const link_TgStat = isOpen
		? `https://tgstat.ru/channel/${sometext}/stat?X6Xfv`
		: `https://tgstat.ru/channel/${sometext}/stat?X6Xfv`
	const link_Telemetr = isOpen
		? `https://telemetr.me/r/${sometext}`
		: `https://telemetr.me/joinchat/${sometext}`

	const handleClick = async key => {
		try {
			if (key === link_TgStat) {
				await navigator.clipboard.writeText(link_TgStat)
			} else if (key === link_Telemetr) {
				await navigator.clipboard.writeText(link_Telemetr)
			}
		} catch (error) {
			console.log('не удалось скопировать ссылку')
		}
	}

	return (
		<div className={style.blockStatistic}>
			<span>{t('needMoreStat')}</span>
			<a href={`${link_TgStat}`} target='_blank' className={style.btnStat}>
				<img className={style.TgStatImg} src={iconTgStats} alt='tgStat' />
				TGSTAT.RU
			</a>
			<a href={`${link_Telemetr}`} target='_blank' className={style.btnStat}>
				<img className={style.TelemetrImg} src={iconTelemetr} alt='telemetr' />
				TELEMETR.ME
			</a>
		</div>
	)
}

export default ButtonLink
