import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './NoteFound.module.scss'

const NotFound = () => {
	const { t } = useTranslation()
	return (
		<div className={style.wrapperNotFound}>
			<span className={style.titleNotFound}>{t('notFound')}</span>
			<span className={style.textNotFound}>{t('pageNotExist')}</span>
		</div>
	)
}

export default NotFound
