import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
export const fetchCategoriesOneAsyncThunk = createAsyncThunk(
	'categoriesOne/CategoriesOneFetch',
	async obj => {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_KEY}/categories/one`,
			{
				params: {
					categories_name: obj.categories_name,
				},
			}
		)
		console.log(data)
		return data.result
	}
)
const initialState = {
	categories_one: [],
	statusOne: 'loading', // loading | success | error
}

const CategoriesOneSlice = createSlice({
	name: 'categories_one',
	initialState,
	reducers: {
		setCategoties(state, action) {
			state.categories_one = action.payload
			state.statusOne = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCategoriesOneAsyncThunk.pending, (state, action) => {
				state.statusOne = 'loading'
			})
			.addCase(fetchCategoriesOneAsyncThunk.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.categories_one = action.payload
				state.statusOne = 'success'
			})
			.addCase(fetchCategoriesOneAsyncThunk.rejected, (state, action) => {
				state.categories_one = []
				state.statusOne = 'error'
			})
	},
})

export default CategoriesOneSlice.reducer
