import { configureStore } from '@reduxjs/toolkit'
import CategoriesSlice from './reducers/CategoriesSlice'
import ChangedPageSlice from './reducers/ChangedPageSlice'

import CategoriesOneSlice from './reducers/CategoriesOneSlice.js'
import { fetchDataChannelSlice } from './reducers/ChannelDataSlice'
import fetchDataChannelOneSlice from './reducers/ChannelOneDataSlice.js'
import clickedLoginSlice from './reducers/ClickedLoginSlice'
import FavoriteSlice from './reducers/FavoriteSlice.js'
import favoriteOneSlice from './reducers/FavoriteSliceOne.js'
import FavoritePutSlice from './reducers/FavoriteSlicePut.js'
import FetchUserChannelsSlice from './reducers/FetchChannelsSlice.js'
import FilterChannelSlice from './reducers/FilterChannelSlice.js'
import GenerateTokenSlice from './reducers/GenerateTokenSlice'
import IsLoginedSlice from './reducers/IsLoginedSlice.js'
import PaginationButtonSlice from './reducers/PaginationButtonSlice.js'
import {
	default as SelectedCatalogArrayProfileSlice,
	default as selectedCatalogArraySlice,
} from './reducers/SelectedCatalogArrayProfileSlice.js'
import SelectedCatalogSlice from './reducers/SelectedCatalogSlice'
import selectedCategoryOrLangSlice from './reducers/SelectedCategoriesSlice.js'
import selectedFilterSlice from './reducers/SelectedFilter'
import SortingSlice from './reducers/SortingSlice.js'
import SubsPutSlice from './reducers/SubsPutSlice'
import ThemeSlice from './reducers/ThemeSlice'
import { FetchUserSlice } from './reducers/UserSlice'
import FetchUserAuthSlice from './reducers/UserSliceAuth.js'
import UserAuthLikeSlice from './reducers/UserSliceAuthForLike.js'
import getSubOneSlice from './reducers/getSubOneSlice'
import getAllSubsSlice from './reducers/getSubSlice.js'
import FetchUserAuthSettingsSlice from './reducers/FetchUserAuthSettingsSlice.js'
import HandleTonSlice from './reducers/HandleTonSlice.js'
export const store = configureStore({
	reducer: {
		selectedCatalog: SelectedCatalogSlice,
		theme: ThemeSlice,
		userData: FetchUserSlice.reducer,
		filterData: selectedFilterSlice,
		channelData: fetchDataChannelSlice.reducer,
		channelOneData: fetchDataChannelOneSlice,
		changePageData: ChangedPageSlice,
		loginData: clickedLoginSlice,
		CategoriesData: CategoriesSlice,
		categoriesOneData: CategoriesOneSlice,
		userDataAuth: FetchUserAuthSlice,
		paginationData: PaginationButtonSlice,
		sortData: SortingSlice,
		filterChannel: FilterChannelSlice,
		selectedCategoryOrLangData: selectedCategoryOrLangSlice,
		favoriteData: FavoriteSlice,
		fetchUserLike: UserAuthLikeSlice,
		fetchFavoritePutLike: FavoritePutSlice,
		fetchChannelUser: FetchUserChannelsSlice,
		selectedCategoriesChannelsData: SelectedCatalogArrayProfileSlice,
		categoryOrLangData: selectedCatalogArraySlice,
		favoriteOneData: favoriteOneSlice,
		UserTokenUUIDv4Data: GenerateTokenSlice,
		subsData: getAllSubsSlice,
		subOneData: getSubOneSlice,
		subsPutData: SubsPutSlice,
		loginTgData: IsLoginedSlice,
		FetchUserAuthSettingsSlice,
		HandleTonSlice
	},
})
