/** @format */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoginedSlice } from "../../../redux/reducers/IsLoginedSlice.js";
import CopyLink from "../CopyLink/CopyLink.jsx";
import style from "./AddMyChannel.module.scss";
const AddMyChannelBtn = () => {
  const { t } = useTranslation();
  const [logined, setLogined] = useState(false);
  const { isLogin } = useSelector((state) => state.loginTgData);
  const dispatch = useDispatch();
  const handlePopup = () => {
    setLogined(!logined);
  };
  const handleClosePopup = () => {
    setLogined(false);
    dispatch(setIsLoginedSlice(false));
  };
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div>
      <div onClick={() => handlePopup()} className={style.wrapperBtnMyChannel}>
        {t("addMyChannel")}
      </div>
      {isLogin === true && (
        <div className={style.wrapperPopupAddBtn}>
          <div className={style.titleBlockAddBtn}>
            <div>
              <span className={style.titlePopup}>{t("addChannel")}</span>
            </div>
            <button
              onClick={handleClosePopup}
              className={style.closeBtn}
            ></button>
          </div>
          <div className={style.wrapperAddChannelBtnOne}>
            <div className={style.popupContent}>
              <div className={style.verticalLineOne}>
                <div className={style.roundVOne}></div>
                <div className={style.lineV}></div>
                <div className={style.roundVOne}></div>
                <div className={style.lineV}></div>
                <div className={style.roundVOne}></div>
                <div className={style.lineV}></div>
              </div>

              <div className={style.contentAddChannel}>
                <span>{t("checkEligibility")}</span>
                <div className={style.lineHor}></div>
                <span>{t("addMyChannelTwo")}</span>
                <div className={style.lineHor}></div>
                <span>{t("loggedOwner")}</span>
              </div>
            </div>
          </div>
          <div className={style.wrapperAddChannelBtn}>
            <div className={style.popupContent}>
              <div className={style.verticalLine}>
                <div className={style.lineV}></div>
                <div className={style.roundV}></div>
                <div className={style.lineV}></div>
                <div className={style.roundV}></div>
                <div className={style.lineV}></div>
                <div className={style.roundV}></div>
              </div>

              <div className={style.contentAddChannel}>
                <span className={style.first}>{t("copyUsername")}</span>
                <label
                  onClick={handleCopy}
                  className={
                    copied === true ? style.labelCopied : style.labelCopyLink
                  }
                >
                  {copied === true ? (
                    <span>{t("copied")}</span>
                  ) : (
                    <span>@advixbot</span>
                  )}
                  <CopyLink />
                </label>
                <div className={style.lineHor}></div>
                <div className={style.spanBlock}>
                  <span>
                    {t("addBot")}
                    <b className={style.spanBlue}>{t("addBot2")}</b>
                    {t("addBot3")}
                  </span>
                </div>
                <div className={style.lineHor}></div>
                <span>{t("returnAdvix")}</span>
              </div>
            </div>
            <div className={style.btnAdd}>{t("addChannel")}</div>
          </div>
        </div>
      )}
      {logined === true && (
        <div className={style.wrapperPopupAddBtn}>
          <div className={style.titleBlockAddBtn}>
            <div>
              <span className={style.titlePopup}>{t("addChannel")}</span>
            </div>
            <button
              onClick={handleClosePopup}
              className={style.closeBtn}
            ></button>
          </div>
          <div className={style.wrapperAddChannelBtnOne}>
            <div className={style.popupContent}>
              <div className={style.verticalLineOne}>
                <div className={style.roundVOne}></div>
                <div className={style.lineV}></div>
                <div className={style.roundVOne}></div>
                <div className={style.lineV}></div>
                <div className={style.roundVOne}></div>
                <div className={style.lineV}></div>
              </div>

              <div className={style.contentAddChannel}>
                <span>{t("checkEligibility")}</span>
                <div className={style.lineHor}></div>
                <span>{t("addMyChannelTwo")}</span>
                <div className={style.lineHor}></div>
                <span>{t("loggedOwner")}</span>
              </div>
            </div>
          </div>
          <div className={style.wrapperAddChannelBtn}>
            <div className={style.popupContent}>
              <div className={style.verticalLine}>
                <div className={style.lineV}></div>
                <div className={style.roundV}></div>
                <div className={style.lineV}></div>
                <div className={style.roundV}></div>
                <div className={style.lineV}></div>
                <div className={style.roundV}></div>
              </div>

              <div className={style.contentAddChannel}>
                <span className={style.first}>{t("copyUsername")}</span>
                <label
                  onClick={handleCopy}
                  className={
                    copied === true ? style.labelCopied : style.labelCopyLink
                  }
                >
                  {copied === true ? (
                    <span>{t("copied")}</span>
                  ) : (
                    <span>@advixbot</span>
                  )}
                  <CopyLink />
                </label>
                <div className={style.lineHor}></div>
                <div className={style.spanBlock}>
                  <span>
                    {t("addBot")}
                    <b className={style.spanBlue}>{t("addBot2")}</b>
                    {t("addBot3")}
                  </span>
                </div>
                <div className={style.lineHor}></div>
                <span>{t("returnAdvix")}</span>
              </div>
            </div>
            <div className={style.btnAdd}>{t("addChannel")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddMyChannelBtn;
