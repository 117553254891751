import { useTranslation } from 'react-i18next'
import style from './InfoAboutPublic.module.scss'
const InfoAboutPublic = ({ type_public }) => {
	const { t } = useTranslation()
	console.log(type_public)
	return (
		<div className={style.wrapperInfoAboutPublic}>
			{type_public ? (
				<span className={style.OpenLink}>
					{t('byTheChannel')} <span>{t('publicLink')}</span> {t('link')}
				</span>
			) : (
				<span className={style.PrivateLink}>
					{t('byTheChannel')} <span>{t('privateLink')}</span> {t('link')}
				</span>
			)}
		</div>
	)
}

export default InfoAboutPublic
