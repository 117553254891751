import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const fetchSubsPut = createAsyncThunk(
	'putSubs/getPutFetch',
	async channel_id => {
		console.log('channel_id', channel_id)
		const API_BASE_URL = process.env.REACT_APP_API_KEY
		const token = Cookies.get('token')
		const { data } = await axios.post(
			`${API_BASE_URL}/subs/add_sub`,
			{
				channel_id,
			},
			{ headers: { Authorization: `Bearer ${token}` } }
		)
		console.log(data)
		return data
	}
)
const initialState = {
	subsPut: {},
	statusPutSubs: 'loading', // loading | success | error
}

const SubsPutSlice = createSlice({
	name: 'subsPut',
	initialState,
	reducers: {
		setSubs(state, action) {
			state.subsPut = action.payload
			state.statusPutSubs = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSubsPut.pending, (state, action) => {
				state.statusPutSubs = 'loading'
			})
			.addCase(fetchSubsPut.fulfilled, (state, action) => {
				state.subsPut = action.payload
				state.statusPutSubs = 'success'
			})
			.addCase(fetchSubsPut.rejected, (state, action) => {
				state.subsPut = {}
				state.statusPutSubs = 'error'
			})
	},
})

export default SubsPutSlice.reducer
