import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import notFoundImg from '../../assets/not_found.png'
import { fetchDataChannel } from '../../redux/reducers/ChannelDataSlice'
import { fetchFavoriteSlice } from '../../redux/reducers/FavoriteSlice.js'
import { setFilters } from '../../redux/reducers/FilterChannelSlice.js'
import { fetchUserAuthForLike } from '../../redux/reducers/UserSliceAuthForLike.js'
import { fetchSubs } from '../../redux/reducers/getSubSlice'
import DropDown from '../DropDown/DropDown'
import Search from '../InputSearch/Search'
import PaginationButton from '../PaginationButton/PaginationButton.jsx'
import LoaderGridChannels from '../Skeleton/SkeletonGridChannels.jsx'
import ChannelComponent from './ChannelComponent/ChannelComponent'
import ChannelComponentHot from './ChannelComponentHot/ChannelComponentHot'
import { find24Price } from './FindDefaultErr.js'
import style from './GridChannels.module.scss'
import { findMinMaxPrices } from './[GridDetailChannels]/minMax/MinMaxPrice.js'
const GridChannels = () => {
	const { t } = useTranslation()
	const args = [
		`${t('cost')}`,
		'CPM',
		`${t('subscribers')}`,
		`${t('viewsOnPost')}`,
		`${t('err')}`,
		`${t('rating')}`,
		`${t('reviews')}`,
	]
	const default_arg = `${t('whithoutSort')}`

	const { pages } = useSelector(state => state.selectedCatalog)
	const dispatch = useDispatch()
	const { count } = useSelector(state => state.paginationData)
	const { subs, statusSubs } = useSelector(state => state.subsData)
	const { sort } = useSelector(state => state.sortData)
	const { filters } = useSelector(state => state.filterChannel)
	const { channels, status, statusNoMore } = useSelector(
		state => state.channelData
	)
	const [filteredChannels, setFilteredChannels] = useState([])
	const [filteredChannelsAndSelect, setFilteredChannelsAndSelect] = useState([])
	const { selectedCategoryOrLang } = useSelector(
		state => state.selectedCategoryOrLangData
	)
	const [filteredData2, setFilteredData2] = useState([])
	const { userAuth, statusAuth } = useSelector(state => state.userDataAuth)
	const { userAuthLike, statusAuthLike } = useSelector(
		state => state.fetchUserLike
	)
	const { favorite, statusFavorite } = useSelector(state => state.favoriteData)
	// const findObjectSelected = (favorite, id) => {
	// 	let result = false
	// 	if (favorite === null) {
	// 		result = false
	// 		return
	// 	}
	// 	favorite !== null &&
	// 		favorite.map(element => {
	// 			if (Number(element.channel_id) === Number(id)) {
	// 				result = true
	// 				return
	// 			}
	// 		})
	// 	return result
	// }
	useEffect(() => {
		dispatch(fetchUserAuthForLike())
	}, [])
	const findObjectSelected = useCallback(
		(favorite, id) => {
			if (!favorite) return false
			return favorite.some(element => Number(element.channel_id) === Number(id))
		},
		[] // зависимостей нет, если функция не зависит от внешних переменных
	)
	const { statusPutSubs } = useSelector(state => state.subsPutData)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const some = {
					all_published: true,
					name_channel: '',
					skip: count,
					take: 1,
					is_search: false,
					sort,
				}
				dispatch(fetchDataChannel(some))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		dispatch(fetchSubs())
	}, [count])
	useEffect(() => {
		if (statusPutSubs === 'success') {
			dispatch(fetchSubs())
		}
	}, [statusPutSubs])
	// useEffect(() => {
	// 	if (statusAuthLike === 'success') {
	// 		const filteredChannelsIds = channels.map(element => ({
	// 			id: Number(element.id),
	// 		}))

	// 		dispatch(
	// 			fetchFavoriteSlice({
	// 				user_id: userAuthLike.User_id,
	// 				channels: filteredChannelsIds,
	// 			})
	// 		)
	// 	}
	// }, [statusAuthLike])

	const fetchDataCallback = useCallback(() => {
		const filteredChannelsIds = channels.map(element => ({
			id: Number(element.id),
		}))

		dispatch(
			fetchFavoriteSlice({
				user_id: userAuthLike.User_id,
				channels: filteredChannelsIds,
			})
		)
	}, [statusAuthLike])

	useEffect(() => {
		if (statusAuthLike === 'success') {
			fetchDataCallback()
		}
	}, [statusAuthLike, fetchDataCallback])

	useEffect(() => {
		if (status === 'success') {
			let maxViewsObject = channels.reduce((maxObj, currentObj) => {
				return currentObj.views > maxObj.views ? currentObj : maxObj
			}, channels[0])

			let minViewsObject = channels.reduce((minObj, currentObj) => {
				return currentObj.views < minObj.views ? currentObj : minObj
			}, channels[0])

			let maxSubsObject = channels.reduce((maxObj, currentObj) => {
				return currentObj.count_subscribers > maxObj.count_subscribers
					? currentObj
					: maxObj
			}, channels[0])
			let minSubsObject = channels.reduce((minObj, currentObj) => {
				return currentObj.count_subscribers < minObj.count_subscribers
					? currentObj
					: minObj
			}, channels[0])
			let maxERRObject = channels.reduce((maxObj, currentObj) => {
				return currentObj.ERR > maxObj.ERR ? currentObj : maxObj
			}, channels[0])
			let minERRObject = channels.reduce((minObj, currentObj) => {
				return currentObj.ERR < minObj.ERR ? currentObj : minObj
			}, channels[0])
			let maxCPMObject = channels.reduce((maxObj, currentObj) => {
				return currentObj.CPM > maxObj.CPM ? currentObj : maxObj
			}, channels[0])
			let minCPMObject = channels.reduce((minObj, currentObj) => {
				return currentObj.CPM < minObj.CPM ? currentObj : minObj
			}, channels[0])

			const Price = findMinMaxPrices(channels)

			dispatch(
				setFilters({
					...filters,
					views: {
						min: minViewsObject.views,
						max: maxViewsObject.views,
					},
					subscribers: {
						min: minSubsObject.count_subscribers,
						max: maxSubsObject.count_subscribers,
					},
					err: {
						min: Math.round(minERRObject.ERR * 100),
						max: Math.round(maxERRObject.ERR * 100),
					},
					price: {
						min: Number(Price.minPrice),
						max: Number(Price.maxPrice),
					},
					cpm: {
						min: Number(minCPMObject.CPM),
						max: Number(maxCPMObject.CPM),
					},
					category: selectedCategoryOrLang.category,
					language: selectedCategoryOrLang.lang,
				})
			)
		}
	}, [status, count, selectedCategoryOrLang])

	useEffect(() => {
		var updatedArr = []
		if (statusFavorite === 'success' && status === 'success') {
			if (!favorite || !channels) return

			updatedArr = channels.map(element => {
				if (findObjectSelected(favorite, element.id)) {
					return { ...element, selected: true }
				} else {
					return { ...element, selected: false }
				}
			})

			setFilteredData2(updatedArr)
		} else {
			const updatedArr = channels.map(element => {
				return { ...element, selected: false }
			})

			setFilteredData2(updatedArr)
		}
	}, [statusFavorite, channels])
	const findNEEDCategory = (arr, lang) => {
		console.log('arr', arr)
		console.log('lang', lang)
		let isFind = false
		arr.map(element => {
			if (element === 'Не выбрано' || element === '') {
				isFind = true
			}
			if (element === lang) {
				isFind = true
			}
		})
		return isFind
	}

	const filteredData = useMemo(() => {
		console.log('filteredData2', filteredData2)
		return filteredData2.filter(element => {
			const categoryMatch = findNEEDCategory(filters.category, element.Category)

			const languageMatch = findNEEDCategory(filters.language, element.language)
			const errMatch =
				Math.round(element.ERR * 100) >= Math.round(filters.err.min) &&
				Math.round(element.ERR * 100) <= Math.round(filters.err.max)
			const cpmMatch =
				element.CPM >= filters.cpm.min && element.CPM <= filters.cpm.max
			const viewsMatch =
				element.views >= filters.views.min && element.views <= filters.views.max
			const subscribersMatch =
				element.count_subscribers >= filters.subscribers.min &&
				element.count_subscribers <= filters.subscribers.max
			const priceMatch =
				find24Price(element.priceObjects) >= filters.price.min &&
				find24Price(element.priceObjects) <= filters.price.max

			const filteredFavorite =
				filters.selected === false ? element : element.selected === true
			const verefiedChannel =
				filters.verify === false ? element : element.verified === true
			const openedChannel =
				!filters.opened_channel !== false
					? element
					: element.link_Type_Boolean === false
			const canBuyTon =
				filters.ton === false ? element : element.can_buy_ton === true
			//can_buy_ton

			return (
				categoryMatch &&
				languageMatch &&
				errMatch &&
				cpmMatch &&
				viewsMatch &&
				subscribersMatch &&
				priceMatch &&
				filteredFavorite &&
				verefiedChannel &&
				openedChannel &&
				canBuyTon
			)
		})
	}, [filters, filteredData2, statusFavorite])
	const findIsSubs = (arr, id) => {
		let isSubs = false
		arr.filter(element => {
			if (Number(element.channel_id) === Number(id)) {
				return (isSubs = true)
			}
		})
		return isSubs
	}
	useEffect(() => {
		let newFiltered = []

		if (statusSubs !== 'success') {
			newFiltered = filteredData
		}
		if (statusSubs === 'success') {
			newFiltered = filteredData.map(element => {
				element.is_sub = findIsSubs(subs, element.id)
				return element
			})
		}

		console.log('newFiltered', newFiltered)
		setFilteredChannels(newFiltered)
	}, [filteredData, statusSubs])

	return (
		<div className={style.wrapperGrid}>
			<div className={style.wrapperHeaderGrid}>
				<Search />
				<DropDown default_arg={default_arg} args={args} />
			</div>
			<div className={style.GridChannelsComponentsWrapper}>
				{status === 'error' && <div>{t('serverError')}</div>}

				{status === 'loading' && <LoaderGridChannels />}

				{status === 'success' && filteredChannels.length > 0 ? (
					filteredChannels.map(element => {
						return pages.label === 'Каталог' ? (
							<React.Fragment key={element.id}>
								{element.is_active && (
									<ChannelComponent
										key={element.name_channel}
										element={element}
									/>
								)}
							</React.Fragment>
						) : (
							<React.Fragment key={element.id}>
								{element.hot_state === true && element.is_active && (
									<ChannelComponentHot
										key={element.name_channel}
										element={element}
										//favorite={favorite}
									/>
								)}
							</React.Fragment>
						)
					})
				) : (
					<div className={style.notFoundImg}>
						<img src={notFoundImg} alt='not found' />
						<p>{t('notFoundRequest')}</p>
					</div>
				)}
			</div>
			{statusNoMore === false && <PaginationButton />}
		</div>
	)
}

export default GridChannels
