import React from 'react'
import premium from '../../../../assets/premium.svg'
import style from './Desciption.module.scss'
const Description = ({ content, owner }) => {
	return (
		<div className={style.wrapperQuotes}>
			<span className={style.quotesStyle}>{content}</span>
			<div className={style.ownerBlock}>
				<span>{owner.name}</span>
				{owner.is_premium === true && <img src={premium} alt='' srcset='' />}
			</div>
		</div>
	)
}

export default Description
