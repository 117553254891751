import { useParams } from 'react-router-dom'
import eye from '../../../assets/eye.svg'
import favorited from '../../../assets/favorited.svg'

import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import favorite1 from '../../../assets/favorite.svg'
import { fetchDataChannelOne } from '../../../redux/reducers/ChannelOneDataSlice'
import { fetchFavoriteOneFetch } from '../../../redux/reducers/FavoriteSliceOne.js'
import { fetchFavoritePut } from '../../../redux/reducers/FavoriteSlicePut.js'
import { fetchUserAuthForLike } from '../../../redux/reducers/UserSliceAuthForLike.js'
import { getSubOneFetch } from '../../../redux/reducers/getSubOneSlice'
import LoaderChannelComponent from '../../Skeleton/SkeletonChannelComponent'
import Button from './Button/Button'
import ButtonLink from './ButtonLink/ButtonLink'
import ButtonLinkTgStat from './ButtonLinkTgStat/ButtonLinkTgStat'
import Description from './Desciption/Description'
import style from './GridDetailChannels.module.scss'
import InfoAboutPublic from './InfoAboutPublic/InfoAboutPublic'
import LinkChannel from './LinkChannel/LinkChannel'
import PriceDefaultBlock from './PriceDefaultBlock/PriceDefaultBlock'
import PriceHotBlock from './PriceHotBlock/PriceHotBlock'
import Statistics from './Statistics/Statistics'
import StatisticsTgStat from './StatisticsTgStat/StatisticsTgStat'
const GridDetailChannels = () => {
	const { t } = useTranslation()
	const resultOne = useParams()
	//console.log('id', id)
	const { statusSub, sub } = useSelector(state => state.subOneData)
	const [id, setId] = useState(null)
	const { statusPutSubs } = useSelector(state => state.subsPutData)
	const [owner, setOwner] = useState({ name: null, is_premium: null })
	const fetchId = async name => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_KEY}/channel/oneID`,
				{
					params: {
						name_channel: name,
						link_Cannel: name,
					},
				}
			)

			return data
		} catch (error) {
			return error
		}
	}

	useEffect(() => {
		fetchId(resultOne.id)
			.then(result => {
				setId(result.id)
				setOwner({ is_premium: result.is_premium, name: result.owner_name })
			})
			.catch(error => {
				console.error('Error fetching ID:', error)
			})
	}, [])

	const dispatch = useDispatch()
	const [likes, setLikes] = useState(0)
	const [clicked, setClicked] = useState(false)
	let elementDetail = []
	const { channels, status, statusNoMore } = useSelector(
		state => state.channelData
	)
	//const {favoriteOne, }
	const { userAuthLike, statusAuthLike } = useSelector(
		state => state.fetchUserLike
	)
	const { statusDelete, statusFavorite, favorite } = useSelector(
		state => state.fetchFavoritePutLike
	)
	const [isDelete, setIsDelete] = useState(null)
	const { favoriteOne, statusFavoriteOne } = useSelector(
		state => state.favoriteOneData
	)

	useEffect(() => {
		const fetchCountLikes = async () => {
			try {
				const result1 = await axios.get(
					`${process.env.REACT_APP_API_KEY}/user-channel-selection/likes_count`,
					{
						params: {
							id: id,
						},
					}
				)

				if (result1.status === 200) {
					setLikes(result1.data.selected.length)
					if (
						result1.data.selected.length === 0 ||
						result1.data.selected.length === undefined ||
						result1.data.selected.length === null
					) {
						setLikes(0)
					}
				} else {
					setLikes(0)
				}
			} catch (error) {
				console.log(error)
			}
		}
		if (id !== null) {
			fetchCountLikes()
		}
	}, [statusDelete, id])

	useEffect(() => {
		if (id !== null) {
			dispatch(fetchDataChannelOne(id))
		}
	}, [id])
	useEffect(() => {
		if (id !== null) {
			dispatch(fetchUserAuthForLike())
		}
	}, [id])
	const fetchDataCallback = useCallback(() => {
		dispatch(
			fetchFavoriteOneFetch({
				user_id: userAuthLike.User_id,
				id_channel: Number(id),
			})
		)
	}, [statusAuthLike])

	useEffect(() => {
		if (id !== null) {
			dispatch(getSubOneFetch(id))
		}
	}, [id, statusPutSubs])
	useEffect(() => {
		if (statusAuthLike === 'success') {
			fetchDataCallback()
		}
	}, [statusAuthLike, fetchDataCallback])

	const data2 = useSelector(state => state.channelOneData)

	const statusOne = data2.statusOne

	if (statusOne === 'success') {
		let priceObjects = data2.channel.priceObjects
		elementDetail = { ...data2.channel.data, priceObjects }
	}

	const ConvertIntToRUNumberFormat = among => {
		return new Intl.NumberFormat('ru', { style: 'decimal' }).format(among)
	}

	function ConvertIntToENNumberFormat(num) {
		if (num >= 1000000) {
			return (num / 1000000).toFixed(1) + 'M'
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1) + 'k'
		} else {
			return num.toString()
		}
	}
	const handleClickedFavorite = () => {
		setClicked(!clicked)

		if (statusAuthLike === 'success') {
			const obj = {
				user_id: userAuthLike.User_id,
				channel_id: parseInt(id),
				statusDelete,
			}
			dispatch(fetchFavoritePut(obj))
		} else {
			alert(`${t('needAuth')}`)
		}
	}

	return (
		<>
			{statusOne === 'loading' && <LoaderChannelComponent />}
			{statusOne === 'success' && (
				<div className={style.wrapperGridDetailChannels}>
					<div className={style.wrapperCatalog}>
						<Button />
						<div className={style.wrapperLinkAndInfoAbout}>
							<InfoAboutPublic type_public={!elementDetail.public_type} />
							<LinkChannel url={elementDetail.link_Cannel} />
						</div>
					</div>
					<div>
						<div className={style.ImgBackground}>
							<img
								src={`${process.env.REACT_APP_API_KEY}${elementDetail.url_background_channel}`}
								alt='background_channel_image'
							/>
						</div>
						<div className={style.wrapperChannelName}>
							<div className={style.wrapperIconAndName}>
								<div className={style.wrapperIcon}>
									<img
										src={`${process.env.REACT_APP_API_KEY}/bot/${elementDetail.url_Image_Channel}`}
										alt='icon channel'
									/>
								</div>
								<div className={style.StatisticsRoot}>
									<div className={style.blockStatisticsNameChannel}>
										<span className={style.StatisticsNameChannel}>
											{elementDetail.name_channel}
										</span>
									</div>
									<div className={style.wrapperCategoryAndPosition}>
										<span className={style.bordedElement}>
											{elementDetail.Category}
										</span>
										<span className={style.bordedElement}>
											#{elementDetail.position}
										</span>
									</div>
									<div className={style.WrapperStatisticsMore}>
										<Statistics data={elementDetail} />
									</div>
									<div className={style.wrapperDesc}>
										<hr className={style.line} />
										<span>{t('desc')}</span>
										<Description
											owner={owner}
											content={
												elementDetail.desc_is_custom === false
													? elementDetail.desc_channel
													: elementDetail.channel_desc_custom
											}
										/>
										<hr className={style.line} />
									</div>
									<div className={style.wrapperStatisticsTitle}>
										<div className={style.wrapperStatsTitle}>
											<span>{t('statisticGrid')}</span>
										</div>
										{/* <button className={style.wrapperBtnTg}>
											<a>TGSTAT.RU</a>
											<img
												src={iconTgStats}
												className={style.TgStatsIcon}
												alt='tgstats'
											/>
										</button> */}
										<ButtonLinkTgStat link_Cannel={elementDetail.link_Cannel} />
									</div>

									<StatisticsTgStat name_channel={elementDetail.link_Cannel} />
								</div>
							</div>
							<div className={style.wrapperStatistics}>
								<div className={style.wrapperTwoStatistics}>
									<div className={style.wrapperCountViews}>
										<span>
											{ConvertIntToENNumberFormat(Number(elementDetail.views))}
										</span>

										<img className={style.IconSize} src={eye} alt='eye' />
									</div>
									<div
										onClick={() => handleClickedFavorite()}
										className={style.wrapperCountViews}
									>
										<span>{ConvertIntToRUNumberFormat(likes)}</span>
										{statusAuthLike === 'loading' ||
											statusFavoriteOne === 'loading' ||
											(statusDelete === 'loading' && (
												<TailSpin
													visible={true}
													height='1.088rem'
													width='1.088rem'
													color='#00A5E9'
													ariaLabel='tail-spin-loading'
													radius='0.025rem'
													wrapperStyle={{
														backgroundColor: '#fff',
														borderRadius: '0.3rem',
													}}
													wrapperClass=''
												/>
											))}
										{statusAuthLike === 'error' && (
											<img
												className={style.IconSize}
												src={favorite1}
												alt='favorite'
											/>
										)}
										{statusDelete === null &&
											statusFavoriteOne !== 'loading' && (
												<img
													className={style.IconSize}
													src={
														statusFavoriteOne === 'success'
															? favorited
															: favorite1
													}
													alt='favorite'
												/>
											)}
										{statusDelete === false && (
											<img
												className={style.IconSize}
												src={favorited}
												alt='favorite'
											/>
										)}
										{statusDelete === true && (
											<img
												className={style.IconSize}
												src={favorite1}
												alt='favorite'
											/>
										)}
									</div>
								</div>
								<PriceDefaultBlock
									owner={owner.name}
									statusSub={statusSub}
									sub={sub}
									data={elementDetail}
								/>
								<PriceHotBlock owner={owner.name} element={elementDetail} />
								<ButtonLink
									name_channel={elementDetail.name_channel}
									link_Cannel={elementDetail.link_Cannel}
									public_type={elementDetail.public_type}
								/>
							</div>
						</div>
						<button
							className={style.btnSlideTop}
							onClick={() => (window.location.href = '#')}
						>
							{t('up')}
						</button>
					</div>
				</div>
			)}
		</>
	)
}

export default GridDetailChannels
