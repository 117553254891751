export function findMinMaxPrices(data) {
	let minPrice = Infinity
	let maxPrice = -Infinity

	data.forEach(channel => {
		channel.priceObjects.forEach(po => {
			if (po.time === 24 && po.hot === false) {
				const price = parseInt(po.price) // Преобразуем цену в число
				if (price < minPrice) minPrice = price
				if (price > maxPrice) maxPrice = price
			}
		})
	})

	// Если ни один объект не был найден, установим minPrice и maxPrice в null
	if (minPrice === Infinity && maxPrice === -Infinity) {
		minPrice = null
		maxPrice = null
	}

	return { minPrice, maxPrice }
}
