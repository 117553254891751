/** @format */

import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchCategotiesAsyncThunk,
	setCategories,
} from '../../redux/reducers/CategoriesSlice'
//import { setCatalogArray } from "../../redux/reducers/SelectedCatalogArrayProfileSlice.js";
//import { setCategoryOrLang } from "../../redux/reducers/SelectedCategoriesSlice";
import done from '../../assets/done.svg'
import done2 from '../../assets/done2.svg'
import iconSearch from '../../assets/search.svg'
import { setCatalogArray } from '../../redux/reducers/SelectedCatalogArrayProfileSlice'
import style from './DropDownFilt.module.scss'
const DropDown2 = ({ id }) => {
	const [name, setName] = useState('Не выбрано')
	const { langAndCategory } = useSelector(state => state.categoryOrLangData)

	useEffect(() => {
		console.log('langAndCategory', langAndCategory, id)
		langAndCategory.forEach(element => {
			if (Number(element.id) === Number(id) && element.category !== '') {
				console.log('element.id', element.id, element.category)
				setName(element.category)
			}
		})
	}, [langAndCategory, id])
	useEffect(() => {
		setSelectedElement([name])
	}, [name])

	const [clicked, setClicked] = useState(false)
	const [inputData, setInputData] = useState(null)
	const [clickedHidden, setClickedHidden] = useState(false)
	const [lastElement, setLastElement] = useState(null)
	//  const [selectedElement, setSelectedElement] = useState(["Не выбрано"]);

	const [categoriesTwo, setCategotiesTwo] = useState([])
	const [isMore, setIsMore] = useState(false)
	const [skip, setSkip] = useState(9)
	const [take, setTake] = useState(9)
	const dispatch = useDispatch()
	const refsLi = useRef([])
	const [changed, setChanged] = useState(false)
	let catMenu = useRef()
	const refDropDownTop = useRef(null)
	const [selectedElement, setSelectedElement] = useState(['Не выбрано'])
	const [selectedElementIndex, setSelectedElementIndex] = useState(null)
	const { selectedCategoryOrLang } = useSelector(
		state => state.selectedCategoryOrLangData
	)
	// useEffect(() => {
	//   setSelectedElement(["Не выбрано"]);
	// }, []);
	const findSelectedElement = (arr, element) => {
		let isFind = false

		arr.forEach(el => {
			if (el === element) {
				isFind = true
			}
		})
		return isFind
	}
	useEffect(() => {
		let handler = e => {
			if (!catMenu.current.contains(e.target)) {
				setClickedHidden(false)
			}
		}

		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	})
	// useEffect(() => {
	//   if (name === null || name === "") {
	//     setSelectedElement("Не выбрано");
	//   } else {
	//     setSelectedElement(name);
	//   }
	// }, [name]);
	const deleteSelectedElement = (arr, element) => {
		let result = []
		result = arr.filter(el => el !== element)
		if (result.length === 0) {
			result = ['Не выбрано']
		}
		return result
	}
	const handleClickedElement = (element, index) => {
		setClicked(true)
		if (findSelectedElement(selectedElement, element)) {
			setSelectedElement(deleteSelectedElement(selectedElement, element))

			// dispatch(
			// 	setFilters({ ...filters, category: [...filters.category, element] })
			// )
			setSelectedElementIndex(null)
			setLastElement(deleteSelectedElement(selectedElement, element).at(-1))
			setClicked(false)
			setIsMore(false)
		} else {
			const ff = selectedElement.filter(element => element !== 'Не выбрано')

			// const updated_categories = categories.map((elementInner) => {
			//   if (elementInner.categories_id === id) {
			//     elementInner.categories_name = element;
			//     return elementInner;
			//   } else {
			//     return elementInner;
			//   }
			// });
			console.log('langAndCategory', langAndCategory, element)
			let result = langAndCategory.map(el => {
				if (Number(el.id) === Number(id)) {
					return { ...el, category: element }
				} else {
					return el
				}
			})

			console.log(result)
			//setCatalogArray
			//console.log("categories_name", element);
			//console.log("categories", categories);

			dispatch(setCatalogArray(result))
			//console.log("updated_categories", updated_categories);
			setSelectedElement([element])
			//dispatch(setCategories(updated_categories));
			// dispatch(
			//   setFilters({ ...filters, category: [...filters.category, element] }),
			// );
			setSelectedElementIndex(index)
			setLastElement(element)
			// dispatch(
			//   setCategoryOrLang({
			//     ...selectedCategoryOrLang,
			//     category: [...ff, element],
			//   }),
			// );
		}
		handleClickedHidden()
	}

	const handleClickedHidden = () => {
		setClickedHidden(!clickedHidden)
	}

	const handleOnScroll = e => {
		e.preventDefault()
		if (categories.length === 45) {
			return
		}

		if (refDropDownTop.current !== null) {
			if (
				refDropDownTop.current.clientHeight +
					refDropDownTop.current.scrollTop >=
				refDropDownTop.current.scrollHeight
			) {
				setSkip(skip + 9)
				dispatch(fetchCategotiesAsyncThunk({ one: false, skip }))
			}
		}
	}

	const handleReload = () => {
		dispatch(setCategories([]))
		setSelectedElement(['Не выбрано'])
		let some = langAndCategory
		let some1 = some.map(element1 => {
			if (element1.id === id) {
				return { ...element1, category: 'Не выбрано' }
			} else {
				return element1
			}
		})
		setSelectedElementIndex(null)
		setClicked(false)
		dispatch(fetchCategotiesAsyncThunk({ one: false, skip: 0 }))

		setSkip(9)
	}
	const { categories, status } = useSelector(state => state.CategoriesData)

	const handleOnChange = e => {
		setInputData(e.target.value)
		const ff = selectedElement.filter(element => {
			if (element !== 'Не выбрано') {
				return element
			}
		})

		if (e.target.value === 'Не выбрано') {
			setTimeout(() => {
				dispatch(
					fetchCategotiesAsyncThunk({
						categories_name: e.target.value,
						one: false,
						skip,
					})
				)
			}, 900)
		} else {
			setTimeout(() => {
				dispatch(
					fetchCategotiesAsyncThunk({
						categories_name: e.target.value,
						one: true,
					})
				)
			}, 900)
		}
	}

	return (
		<div className={style.wrapperRootDropDown}>
			<div ref={catMenu} className={style.wrapperDropDown}>
				<section
					className={
						selectedElement[0] !== 'Не выбрано'
							? style.btnSort
							: style.btnSortTrue
					}
					onClick={() => handleClickedHidden()}
				>
					{selectedElement.map(element => (
						<article className={element !== 'Не выбрано' && style.wrapperBlock}>
							{element}
						</article>
					))}
				</section>
				<div className={style.wrapperPolygon}>
					<div className={style.wrapperBlockFlex}>
						<div className={style.block}></div>
						<div className={style.block}></div>
						<div className={style.block}></div>
						<div className={style.block}></div>
					</div>
					<div
						className={
							clickedHidden
								? `${style.polygonDropDown}`
								: `${style.polygonDropDownRotate}`
						}
					></div>
				</div>
				{clickedHidden && categories.length > 0 && (
					<ul onScroll={handleOnScroll} ref={refDropDownTop}>
						<li className={style.firstLi}>
							<div className={style.wrapperInput}>
								<img
									className={style.img_search}
									src={iconSearch}
									alt='search'
								/>
								<input
									onChange={handleOnChange}
									value={inputData}
									type='text'
									placeholder='Поиск'
								/>
							</div>
						</li>
						{categories.map((element, index) => (
							<li
								className={style.lastLi}
								key={index}
								ref={el => (refsLi.current[index] = el)}
								onClick={() => {
									handleClickedElement(element.categories_name, index)
								}}
								style={{
									backgroundColor:
										findSelectedElement(
											selectedElement,
											element.categories_name
										) && lastElement === element.categories_name
											? '#00a5e9'
											: 'transparent',
									color:
										findSelectedElement(
											selectedElement,
											element.categories_name
										) && lastElement === element.categories_name
											? '#fff'
											: '#99a8b2',
								}}
							>
								{findSelectedElement(
									selectedElement,
									element.categories_name
								) && (
									<div
										className={
											lastElement === element.categories_name
												? style.wrapperDoneIcon
												: style.wrapperDoneIconBLUE
										}
									>
										<img
											src={
												lastElement === element.categories_name ? done : done2
											}
											alt='d'
										/>
									</div>
								)}
								<div className={style.wrapperNameCategories}>
									{' '}
									{element.categories_name}
								</div>
							</li>
						))}
					</ul>
				)}
			</div>
			{/* <button className={style.reload_btn} onClick={handleReload}>
        <img src={icoReload} alt="iconReload" className={style.imgIcon} />
      </button> */}
		</div>
	)
}

export default DropDown2
