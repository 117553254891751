/** @format */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import icoVerify from '../../assets/icons/check_verify.svg'
import icoClearFilt from '../../assets/icons/clearFilt.svg'
import icoHeartStroke from '../../assets/icons/favorite.svg'
import {
	default as icoHeart,
	default as saved,
} from '../../assets/icons/favorite_heart.svg'
import icoDiamond from '../../assets/icons/icoDiamond.svg'
import icoLock from '../../assets/icons/lock_open1.svg'
import icoReload from '../../assets/icons/reload.svg'
import icoSave from '../../assets/icons/save.svg'
import { fetchCategotiesAsyncThunk } from '../../redux/reducers/CategoriesSlice'
import { setFilters } from '../../redux/reducers/FilterChannelSlice.js'
import { setIsTon } from '../../redux/reducers/HandleTonSlice.js'
import { setCategoryOrLang } from '../../redux/reducers/SelectedCategoriesSlice.js'
import DropDown from '../DropDownFilt/DropDownFilt'
import DropDownLang from '../DropDownLang/DropDownLang.jsx'
import { findMinMaxPricesArr } from '../GridChannels/[GridDetailChannels]/minMax/MinMaxPriceForRealod.js'
import style from './Filters.module.scss'

function Filter() {
	const { t } = useTranslation()
	const categoryList = [
		'Telegram',
		'Новости и СМИ',
		'Литература',
		'Криптовалюта',
		'Искусство',
		'Путешествия',
		'Юмор и приколы',
	]
	const dispatch = useDispatch()
	const { filters } = useSelector(state => state.filterChannel)
	useEffect(() => {
		dispatch(fetchCategotiesAsyncThunk({ one: false, skip: 0 }))
	}, [dispatch])
	const default_argCategory = 'Не выбрано'

	const langList = [
		'Русский',
		'Английский',
		'Узбекский',
		'Украинский',
		'Казахский',
		'Белорусский',
		'Арабский',
	]
	const default_argLang = 'Не выбрано'
	const [isSave, setIsSave] = useState(false)
	const minCost = 0
	const maxCost = 100000
	const { channels, status } = useSelector(state => state.channelData)

	console.log(channels.map(item => item.views))
	useEffect(() => {
		const keys = ['price', 'cpm', 'subscribers', 'views', 'err']
		keys.forEach(key => {
			updateRange(key)
		})
	}, [channels]) // Обновляем только при изменении данных

	const updateRange = key => {
		let values
		if (key === 'price') {
			values = findMinMaxPricesArr(channels)
		} else if (key === 'cpm') {
			values = channels.map(item => item.CPM)
		} else if (key === 'subscribers') {
			values = channels.map(item => item.count_subscribers)
		} else if (key === 'views') {
			values = channels.map(item => item.views)
		} else if (key === 'err') {
			values = channels.map(item => item.ERR * 100)
		}

		const min = Math.min(...values)
		const max = Math.max(...values)
		dispatch(
			setFilters({
				...filters,
				[key]: { min, max },
			})
		)
		// console.log("in updateRange key ", key);
		// console.log("in updateRange val ", values);
	}
	const [changed, setChanged] = useState([
		{ key: 'price', statusMin: false, statusMax: false, min: null, max: null },
		{ key: 'cpm', statusMin: false, statusMax: false, min: null, max: null },
		{
			key: 'subscribers',
			statusMin: false,
			statusMax: false,
			min: null,
			max: null,
		},
		{ key: 'views', statusMin: false, statusMax: false, min: null, max: null },
		{ key: 'err', statusMin: false, statusMax: false, min: null, max: null },
	])
	const handleIsChanged = (key, field) => {
		console.log('some', key, field)
		let isField = false
		if (field === 'min') {
			isField = true
		}
		const updated = changed.map(element => {
			if (isField === true && element.key === key) {
				element.statusMin = true
				return element
			} else if (isField === false && element.key === key) {
				element.statusMax = true
				return element
			} else {
				return element
			}
		})
		setChanged(updated)
		console.log('in handleIsChanged key ', key)
		console.log('in handleIsChanged field ', field)
	}
	const findChangeOneWithKey = (key, isField) => {
		let el1 = {}
		let MinOrMax = false
		if (isField === 'min') {
			MinOrMax = true
		}
		changed.filter(el => {
			if (el.key === key && MinOrMax === true) {
				return (el1 = el.statusMin)
			} else if (el.key === key && MinOrMax === false) {
				return (el1 = el.statusMax)
			}
		})
		// console.log("in findChangeOneWithKey key ", key);
		// console.log("in findChangeOneWithKey isField ", isField);
		return el1
	}

	const handleRangeChange = (key, field) => event => {
		const value = event.target.value
		if (isNaN(value)) {
			console.log('Please enter a valid number')
		} else {
			handleIsChanged(key, field)
			dispatch(
				setFilters({
					...filters,
					[key]: {
						...filters[key],
						[field]: Number(value),
					},
				})
			)
		}
	}

	const handleReload = key => () => {
		console.log('key ', key)
		updateRange(key)
		// const keys = ["price", "cpm", "subscribers", "views", "err"];
		// keys.forEach((key) => {
		//   updateRange(key);
		// });
	}
	const { selectedCategoryOrLang } = useSelector(
		state => state.selectedCategoryOrLangData
	)

	const [like, setLike] = useState(false)
	const [online, setOnline] = useState(false)
	const [open, setOpen] = useState(false)
	const [verify, setVerify] = useState(false)
	const [check, setCheck] = useState(false)
	const [checkTon, setCheckTon] = useState(false)

	const [tonRub, setTonRub] = useState(false)
	const handleRubTon = () => {
		setTonRub(!tonRub)
		if (tonRub === false) {
			setCheckTon(true)
			dispatch(setIsTon(true))
			dispatch(
				setFilters({
					...filters,
					ton: true,
				})
			)
		} else if (tonRub === true) {
			setCheckTon(false)
			dispatch(setIsTon(false))
			dispatch(
				setFilters({
					...filters,
					ton: false,
				})
			)
		}
	}

	const handleLikeChange = like => {
		setLike(!like)
		dispatch(
			setFilters({
				...filters,
				selected: !like,
			})
		)
	}
	const handleOnlineChange = () => {
		setOnline(!online)
		// console.log("checkbox", online);
		dispatch(
			setFilters({
				...filters,
				online: !online,
			})
		)
	}

	const handleOpenChange = open => {
		// console.log("it a work");
		setOpen(!open)
		dispatch(
			setFilters({
				...filters,
				opened_channel: !open,
			})
		)
	}
	const handleVerifyChange = verify => {
		setVerify(!verify)
		dispatch(
			setFilters({
				...filters,
				verify: !verify,
			})
		)
	}

	const handleCheckChange = check => {
		setCheck(!check)
		dispatch(
			setFilters({
				...filters,
				checked_channel: !check,
			})
		)
	}

	const handleTonChange = checkTon => {
		setCheckTon(!checkTon)
		dispatch(
			setFilters({
				...filters,
				ton: !checkTon,
			})
		)
	}

	const handleSaveSearch = () => {
		localStorage.setItem('filter', JSON.stringify(filters))
		setIsSave(!isSave)
		// Действия при нажатии на кнопку "Сохранить поиск"
	}

	const handleRestoreSearch = () => {
		const result = localStorage.getItem('filter')

		dispatch(setFilters(JSON.parse(result)))
		// Действия при нажатии на кнопку "Восстановить поиск"
	}
	const refresh = () => {
		const keys = ['price', 'cpm', 'subscribers', 'views', 'err']
		keys.forEach(key => {
			updateRange(key)
		})
		setOnline(false)
		setLike(false)
		setOpen(false)
		setVerify(false)
		setCheck(false)
		setCheckTon(false)
		dispatch(
			setFilters({
				...filters,
				ton: false,
				selected: false,
				opened_channel: false,
				verify: false,
				checked_channel: false,
				online: false,
				category: ['Не выбрано'],
				language: ['Не выбрано'],
			})
		)
		dispatch(
			setCategoryOrLang({
				...selectedCategoryOrLang,
				category: ['Не выбрано'],
				lang: ['Не выбрано'],
			})
		)
	}

	return (
		<div className={style.filter}>
			<div className={style.filter_wrapper}>
				<div className={style.filter_block}>
					<div className={style.filter_item}>
						<span>{t('categoryChannel')}</span>
						<DropDown />
					</div>

					<div className={style.filter_item}>
						<span>{t('categoryLang')}</span>
						<DropDownLang
							className={style.drop_down_lang}
							default_arg={default_argLang}
							args={langList}
						/>
					</div>
				</div>

				<div className={style.filter_block}>
					<div className={style.filter_item}>
						<span>
							{t('costInTonRub')}{' '}
							{filters.ton ? (
								<b className={style.tonFilt}>{t('buyTon2')}</b>
							) : (
								<b className={style.rubFilt}>{t('rub')}</b>
							)}
						</span>
						<label className={filters.ton ? style.switchTon : style.switchRub}>
							<button
								className={filters.ton ? style.btnRoundTon : style.btnRoundRub}
								onClick={handleRubTon}
							></button>
						</label>
						<div className={style.filter_content}>
							<label className={style.label_wrapper}>
								<span>{t('from')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.price.min
									)}
									value={
										findChangeOneWithKey('price', 'min') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.price.min)
									}
									onChange={handleRangeChange('price', 'min')}
								/>
							</label>
							<div className={style.dash}></div>
							<label className={style.label_wrapper}>
								<span>{t('to')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.price.max
									)}
									value={
										findChangeOneWithKey('price', 'max') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.price.max)
									}
									onChange={handleRangeChange('price', 'max')}
								/>
							</label>
							<button
								className={style.reload_btn}
								onClick={handleReload('price')}
							>
								<img
									src={icoReload}
									alt='iconReload'
									className={style.imgIcon}
								/>
							</button>
						</div>
					</div>

					<div className={style.filter_item}>
						<span>CPM</span>
						<div className={style.filter_content}>
							<label className={style.label_wrapper}>
								<span>{t('from')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.cpm.min
									)}
									value={
										findChangeOneWithKey('cpm', 'min') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.cpm.min)
									}
									onChange={handleRangeChange('cpm', 'min')}
								/>
							</label>
							<div className={style.dash}></div>
							<label className={style.label_wrapper}>
								<span>{t('to')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.cpm.max
									)}
									value={
										findChangeOneWithKey('cpm', 'max') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.cpm.max)
									}
									onChange={handleRangeChange('cpm', 'max')}
								/>
							</label>
							<button
								className={style.reload_btn}
								onClick={handleReload('cpm')}
							>
								<img
									src={icoReload}
									alt='iconReload'
									className={style.imgIcon}
								/>
							</button>
						</div>
					</div>
				</div>

				<div className={style.filter_block}>
					<div className={style.filter_item}>
						<span>{t('subs')}</span>
						<div className={style.filter_content}>
							<label className={style.label_wrapper}>
								<span>{t('from')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.subscribers.min
									)}
									value={
										findChangeOneWithKey('subscribers', 'min') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(
													filters.subscribers.min
											  )
									}
									onChange={handleRangeChange('subscribers', 'min')}
								/>
							</label>
							<div className={style.dash}></div>
							<label className={style.label_wrapper}>
								<span>{t('to')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.subscribers.max
									)}
									value={
										findChangeOneWithKey('subscribers', 'max') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(
													filters.subscribers.max
											  )
									}
									onChange={handleRangeChange('subscribers', 'max')}
								/>
							</label>
							<button
								className={style.reload_btn}
								onClick={handleReload('subscribers')}
							>
								<img
									src={icoReload}
									alt='iconReload'
									className={style.imgIcon}
								/>
							</button>
						</div>
					</div>

					<div className={style.filter_item}>
						<span>{t('postCover')}</span>
						<div className={style.filter_content}>
							<label className={style.label_wrapper}>
								<span>{t('from')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.views.min
									)}
									value={
										findChangeOneWithKey('views', 'min') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.views.min)
									}
									onChange={handleRangeChange('views', 'min')}
								/>
							</label>
							<div className={style.dash}></div>
							<label className={style.label_wrapper}>
								<span>{t('to')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.views.max
									)}
									value={
										findChangeOneWithKey('views', 'max') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.views.max)
									}
									onChange={handleRangeChange('views', 'max')}
								/>
							</label>
							<button
								className={style.reload_btn}
								onClick={handleReload('views')}
							>
								<img
									src={icoReload}
									alt='iconReload'
									className={style.imgIcon}
								/>
							</button>
						</div>
					</div>

					<div className={style.filter_item}>
						<span>{t('err')}</span>
						<div className={style.filter_content}>
							<label className={style.label_wrapper}>
								<span>{t('from')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.err.min
									)}
									value={
										findChangeOneWithKey('err', 'min') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.err.min)
									}
									onChange={handleRangeChange('err', 'min')}
								/>
							</label>
							<div className={style.dash}></div>
							<label className={style.label_wrapper}>
								<span>{t('to')}</span>
								<input
									type='number'
									placeholder={new Intl.NumberFormat('ru-RU').format(
										filters.err.max
									)}
									value={
										findChangeOneWithKey('err', 'max') === false
											? null
											: new Intl.NumberFormat('ru-RU').format(filters.err.max)
									}
									onChange={handleRangeChange('err', 'max')}
								/>
							</label>
							<button
								className={style.reload_btn}
								onClick={handleReload('err')}
							>
								<img
									src={icoReload}
									alt='iconReload'
									className={style.imgIcon}
								/>
							</button>
						</div>
					</div>
				</div>

				<div className={style.filter_block_radio}>
					<div className={style.wrapper_radio_btn}>
						<input
							type='radio'
							id='like'
							checked={filters.selected}
							onClick={() => handleLikeChange(filters.selected)}
						/>
						<label htmlFor='like' className={style.label_radio}>
							{t('like')}
							<img src={icoHeart} alt='iconHeart' className={style.imgIcon} />
						</label>
					</div>

					<div className={style.wrapper_radio_btn}>
						<input
							type='checkbox'
							id='online'
							checked={filters.online}
							onChange={handleOnlineChange}
						/>
						<label htmlFor='online' className={style.label_radio}>
							{t('adminOnline')}
							<div className={style.round}></div>
						</label>
					</div>

					<div className={style.wrapper_radio_btn}>
						<input
							type='radio'
							id='open'
							checked={filters.opened_channel}
							onClick={() => handleOpenChange(filters.opened_channel)}
						/>
						<label htmlFor='open' className={style.label_radio}>
							{t('openChannel')}
							<img src={icoLock} alt='iconLock' className={style.imgLockIcon} />
						</label>
					</div>

					<div className={style.wrapper_radio_btn}>
						<input
							type='radio'
							id='verify'
							checked={filters.verify}
							onClick={() => handleVerifyChange(filters.verify)}
						/>
						<label htmlFor='verify' className={style.label_radio}>
							{t('channelVerify')}
							<img
								src={icoVerify}
								alt='iconVerify'
								className={style.imgVerifyIcon}
							/>
						</label>
					</div>

					<div className={style.wrapper_radio_btn}>
						<input
							type='radio'
							id='check'
							checked={check}
							onClick={() => handleCheckChange(check)}
						/>
						<label htmlFor='check' className={style.label_radio}>
							{t('checkChannel')}
						</label>
					</div>

					<div className={style.wrapper_radio_btn}>
						<input
							type='radio'
							id='ton'
							checked={filters.ton}
							onClick={() => handleTonChange(checkTon)}
						/>
						<label htmlFor='ton' className={style.label_radio}>
							<span>
								{t('buyTon')}
								<b className={style.boldBlue}>{t('buyTon2')}</b>
							</span>
							<img
								src={icoDiamond}
								alt='iconDiamond'
								className={style.imgLockIcon}
							/>
						</label>
					</div>
				</div>

				<div className={style.wrapper_search}>
					<button className={style.btn_search} onClick={handleSaveSearch}>
						<img
							src={isSave ? saved : icoHeartStroke}
							alt='iconHeartStroke'
							className={style.imgLikedIcon}
						/>
						{t('saveSearch')}
					</button>

					<button className={style.btn_search} onClick={handleRestoreSearch}>
						<img src={icoSave} alt='iconSave' className={style.imgSaveIcon} />
						{t('rebootSearch')}
					</button>
				</div>
				<div>
					<button onClick={() => refresh()} className={style.btn_clear}>
						<img src={icoClearFilt} alt='clear' />
						{t('clearFilts')}
					</button>
				</div>
			</div>
			{/* <button onClick={() => refresh()} className={style.filter_ico}>
        <img src={icoFilter} alt="filter" />
      </button> */}
		</div>
	)
}

export default Filter
