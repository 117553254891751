import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
export const fetchCategotiesAsyncThunk = createAsyncThunk(
  "categories/CategoriesFetch",
  async (obj) => {
    if (obj.one === false) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/categories/all`,
        {
          params: { skip: obj.skip, take: 9, one: obj.one },
        },
      );

      return data;
    }
    if (obj.one === true) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/categories/one`,
        {
          params: {
            categories_name: obj.categories_name,
            one: obj.one,
          },
        },
      );

      return data;
    }
  },
);
const initialState = {
  categories: [],
  status: "loading", // loading | success | error
};

const CategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
      state.status = "success";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategotiesAsyncThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCategotiesAsyncThunk.fulfilled, (state, action) => {
        if (JSON.parse(action.payload.one) === true) {
          state.categories = action.payload.result;
        } else {
          state.categories = [
            ...state.categories,
            ...action.payload.result.filter(
              (item) =>
                !state.categories.some(
                  (category) => category.categories_id === item.categories_id,
                ),
            ),
          ];
        }

        state.status = "success";
      })
      .addCase(fetchCategotiesAsyncThunk.rejected, (state, action) => {
        state.categories = [];
        state.status = "error";
      });
  },
});
export const { setCategories } = CategoriesSlice.actions;
export default CategoriesSlice.reducer;
