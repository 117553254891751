import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	isTon: false,
}

const HandleTonSlice = createSlice({
	name: 'isTon',
	initialState,
	reducers: {
		setIsTon(state, action) {
			state.isTon= action.payload
		},
	},
})
export const {setIsTon} =  HandleTonSlice.actions
export default HandleTonSlice.reducer
