import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import Auth from './components/Auth/Auth'
import Channels from './components/Channels/Channels'
import GridDetailChannels from './components/GridChannels/[GridDetailChannels]/GridDetailChannels'
import MainLayout from './components/HeaderOutlet/MainLayout'
import NoteFound from './components/NoteFound/NoteFound'
import Profile from './components/Profile/Profile'
import { themeReducer } from './redux/reducers/ThemeSlice'
const App = () => {
	const dispatch = useDispatch()

	let theme_cook = Cookies.get('theme')
	useEffect(() => {
		console.log('theme_cook', theme_cook)
		if (theme_cook === null || theme_cook === undefined) {
			dispatch(themeReducer(true))
		} else {
			dispatch(themeReducer(JSON.parse(theme_cook)))
		}
	}, [theme_cook, dispatch])

	return (
		<>
			<Routes>
				<Route path='/' element={<MainLayout />}>
					<Route path='/auth' element={<Auth />} />
					<Route path='/channels' element={<Channels />} />
					<Route path='/channels/:id' element={<GridDetailChannels />} />

					<Route path='/profile/:id' element={<Profile />} />
					<Route path='*' element={<NoteFound />} />
				</Route>
			</Routes>
		</>
	)
}

export default App
