import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const fetchGeneratedToken = createAsyncThunk(
	'generateToken/generateTokenFetch',
	async obj => {
		const API_BASE_URL = process.env.REACT_APP_API_KEY
		const { data } = await axios.post(`${API_BASE_URL}/auth/auth2`, {
			token_uuid: obj.tokenLocal,
		})

		return data
	}
)
const initialState = {
	token: '',
	statusToken: 'loading', // loading | success | error
}

const generateTokenSlice = createSlice({
	name: 'token',
	initialState,
	reducers: {
		setTokenUUID(state, action) {
			state.token = action.payload
			state.statusToken = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchGeneratedToken.pending, (state, action) => {
				state.statusToken = 'loading'
			})
			.addCase(fetchGeneratedToken.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.token = action.payload.result.token
				Cookies.set('token_uuid', action.payload.result.token)
				state.statusToken = 'success'
			})
			.addCase(fetchGeneratedToken.rejected, (state, action) => {
				state.token = ''
				state.statusToken = 'error'
			})
	},
})

export default generateTokenSlice.reducer
