import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	selectedCategoryOrLang: {
		category: ['Не выбрано'],
		lang: ['Не выбрано'],
	},
}

const selectedCategoryOrLangSlice = createSlice({
	name: 'selectedCategoryOrLang',
	initialState,
	reducers: {
		setCategoryOrLang(state, action) {
			state.selectedCategoryOrLang = action.payload
		},
		deleteCategoryOrLang(state) {
			state.selectedCategoryOrLang = []
		},
	},
})
export const { setCategoryOrLang, deleteCategoryOrLang } =
	selectedCategoryOrLangSlice.actions

export default selectedCategoryOrLangSlice.reducer
