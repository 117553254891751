/** @format */

import { useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import imgCopy from "../../../assets/icons/copy.svg"
import { fetchUserAuthSettings } from "../../../redux/reducers/FetchUserAuthSettingsSlice.js"
import style from "./ProfileInner.module.scss"

const ProfileInner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserAuthSettings());
  }, [dispatch]);
  const { userAuth } = useSelector((state) => state.FetchUserAuthSettingsSlice);
  useEffect(() => {
    console.log(userAuth);
  }, [userAuth]);
  const [copied, setCopied] = useState(false);
  const [copiedUserId, setCopiedUserId] = useState(false);

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const handleCopyUserId = () => {
    setCopiedUserId(true);

    setTimeout(() => {
      setCopiedUserId(false);
    }, 2000);
  };
  return (
    <div className={style.wrapperProfileInner}>
      <section>
        <article>
          <span>Advix ID</span>
          <CopyToClipboard text={userAuth.User_id}>
            <div
              onClick={handleCopyUserId}
              className={
                copiedUserId === true ? style.divCopied : style.divCopyLink
              }
            >
              {copiedUserId === true ? (
                <span>{t("copied")}</span>
              ) : (
                <span>{userAuth.User_id}</span>
              )}
              <img className={style.imgCopyLink} src={imgCopy} alt="copy" />
            </div>
          </CopyToClipboard>
        </article>

        <article>
          <span>Telegram ID</span>
          <CopyToClipboard text={userAuth.id_telegram}>
            <div
              onClick={handleCopy}
              className={copied === true ? style.divCopied : style.divCopyLink}
            >
              {copied === true ? (
                <span>{t("copied")}</span>
              ) : (
                <span>{userAuth.id_telegram}</span>
              )}
              <img className={style.imgCopyLink} src={imgCopy} alt="copy" />
            </div>
          </CopyToClipboard>
        </article>
      </section>
    </div>
  );
};

export default ProfileInner;
