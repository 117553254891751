import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserAuth } from "../../redux/reducers/UserSliceAuth.js"
import GridChannels from "../GridChannels/GridChannels"
import WrapperFilter from "../wrapperFilter/WrapperFilter"
import style from "./Channels.module.scss"
const Channels = () => {
  const dispatch = useDispatch();
  // const { status } = useSelector((state) => state.userData);
  const { statusAuth } = useSelector((state) => state.userDataAuth);
  useEffect(() => {
    dispatch(fetchUserAuth());
  }, [dispatch]);
  useEffect(() => {}, []);

  return (
    <div className={style.wrapperChannels}>
      <WrapperFilter statusAuth={statusAuth} />
      <GridChannels />
    </div>
  );
};

export default Channels;
