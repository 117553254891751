import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	langAndCategory: [	{id: null, category: '',
		lang: '',}],
}

const selectedCatalogArraySlice = createSlice({
	name: 'selectedCatalogArray',
	initialState,
	reducers: {
		setCatalogArray(state, action) {
			state.langAndCategory = action.payload
		},
		deleteCatalogArrSlice(state) {
			state.langAndCategory = {}
		},
	},
})
export const { setCatalogArray, deleteCatalogArrSlice } =
	selectedCatalogArraySlice.actions

export default selectedCatalogArraySlice.reducer
