/** @format */

import Cookies from "js-cookie";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import iconExclamation from "../../../assets/exclamation.svg";
import rub from "../../../assets/newEditor/rub.svg";
import ton from "../../../assets/newEditor/ton.svg";
import DateInput from "./DateInput/DateInput.jsx";
import "./datepickerStyles.css";
// import { sortedPriceObjects } from "../Sorting/Sorting";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import substract from "../../../assets/newEditor/Subtract.svg";
import substract2 from "../../../assets/newEditor/Subtract_2.svg";
import style from "./Editor.module.scss";

import axios from "axios";
import { useLocation } from "react-router-dom";
import { fetchCategotiesAsyncThunk } from "../../../redux/reducers/CategoriesSlice.js";
import { setCategoryOrLang } from "../../../redux/reducers/SelectedCategoriesSlice.js";
import DropDown2 from "../../DropDownFilt2/DropDownFilt.jsx";
import DropDownLang2 from "../../DropDownLang2/DropDownLang.jsx";
import { assignIdsBasedOnTime } from "./SortAssignIdsBasedOnTime/SortAssignIdsBasedOnTime";
import { sortPrice } from "./SortPriceSettings/SortPriceSetting";

export const Editor = (props) => {
  const { t } = useTranslation();
  const data = props.data;
  const location = useLocation();

  const id_some = data.id;
  const fileInputRef = useRef(null);
  const [category, setCategory] = useState("");
  const [defaultPrice, setDefaultPrice] = useState(null);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [costAdverts, setCostAdverts] = useState(["", "", ""]);
  const [customV2Desc, SetCustomV2Desc] = useState(null);
  const [isNewPriceObjects, setIsNewPriceObjects] = useState(false);
  const [correcltyData, setCorrectlyData] = useState(false);
  const [linkImage, setLinkImage] = useState(null);
  const [isNotActive, setIsNotActive] = useState(false);
  const [isClicked, setIsCliked] = useState([
    { clicked: false, id: 1 },
    { clicked: false, id: 2 },
    { clicked: false, id: 3 },
  ]);
  const [isHot, setIsHot] = useState(true);

  useEffect(() => {
    if (data.is_active === false) {
      setIsNotActive(false);
    } else {
      setIsNotActive(true);
    }
  }, []);

  const [save, setSave] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isActive, setIsActive] = useState({
    is_pause: false,
    is_published: false,
    is_kicked: false,
    is_moderation: false,
  });
  const [channel, setChannel] = useState("");
  const [clicked, setClicked] = useState(false);
  const [clickedHidden, setClickedHidden] = useState(false);
  const [lastId, setLastId] = useState(null);
  const [IsCanPublic, setIsCanPublic] = useState(false);
  const [clickedTypeTime, setClickedTypeTime] = useState({
    24: false,
    48: false,
    72: false,
    0: false,
    1: false,
    2: false,
  });

  //const [startDate0, setStartDate0] = useState(new Date())
  //const [timeRemaining0, setTimeRemaining0] = useState('')
  const [timeIdSelected, setTimeIdSelected] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategotiesAsyncThunk({ one: false, skip: 0 }));
  }, [dispatch]);
  const [min, setMin] = useState([
    { id: null, price: false },
    { id: null, price: false },
    { id: null, price: false },
  ]);
  const { langAndCategory } = useSelector((state) => state.categoryOrLangData);
  useEffect(() => {
    const selectedCategory1 = langAndCategory.filter((element) => {
      if (element.id === data.id) {
        return element.category;
      }
    });
    const selectedLang = langAndCategory.filter((element) => {
      if (element.id === data.id) {
        return element.lang;
      }
    });

    setCategory(selectedCategory1);
    let is_activeOne = data.is_published;
    let is_pauseOne = data.is_pause;
    let is_kickedOne = data.is_kicked;
    let is_moderationOne = data.is_moderation;
    setIsActive({
      is_published: is_activeOne,
      is_pause: is_pauseOne,
      is_kicked: is_kickedOne,
      is_moderation: is_moderationOne,
    });
    dispatch(setCategoryOrLang(data.language));
    setDefaultPrice(data.default_price);

    //setDescIsCustom(data.desc_is_custom)
    if (data.desc_is_custom === true) {
      setDescription(data.channel_desc_custom);
      setDescIsCustom(true);
    } else {
      setDescription(data.desc_channel);
      setDescIsCustom(false);
    }
    //setDescIsCustom(data.desc_is_custom)
  }, [data, langAndCategory]);

  //  =================== DROP DOWN ================================
  const categoryList = [
    "Telegram",
    "Новости и СМИ",
    "Литература",
    "Криптовалюта",
    "Искусство",
    "Путешествия",
    "Юмор и приколы",
  ];

  const default_argCategory = "Не выбрано";

  const langList = [
    "Русский",
    "Английский",
    "Узбекский",
    "Украинский",
    "Казахский",
    "Белорусский",
  ];
  const default_argLang = "Не выбрано";
  const [checker, setChecker] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(default_argCategory);
  const [selectedLang, setSelectedLang] = useState(default_argLang);
  const [idOne, setIdOne] = useState(0);
  const [descIsCustom, setDescIsCustom] = useState(null);
  const { categories, status } = useSelector((state) => state.CategoriesData);

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleLangChange = (value) => {
    setSelectedLang(value);
  };
  const displayRef = useRef(null);
  const displayRef2 = useRef(null);
  const displayRef3 = useRef(null);
  const [isDisplay, setIsDisplay] = useState(true);
  // =========================================================
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [condition, setCondition] = useState([
    {
      id: null,
      time: 48,
      state: false,
    },
    {
      id: null,
      time: 72,
      state: false,
    },
    {
      id: null,
      time: 0,
      state: false,
    },
    {
      id: null,
      time: 1,
      state: false,
    },
    {
      id: null,
      time: 2,
      state: false,
    },
  ]);

  const current_desc = data.desc_channel;
  const { selectedCategoryOrLang } = useSelector(
    (state) => state.selectedCategoryOrLangData
  );
  const [isUpdate, setIsUpdate] = useState(0);
  const [priceSettings, setPriceSettings] = useState([
    {
      id: 1,
      default_price: null,
      default_price_token: null,
      hot_price_ton: null,
      current_time: 24,
      dis: null,
      hot_price: null,
      hot_date: null,
      state: false,
      open: false,
      hot: false,
      for_hot: false,
      currently_date: null,
      hot_date: null,
      id_default: null,
      id_hot: null,
      time_remain: null,
    },
    {
      id: 2,
      dis: null,
      default_price: null,
      default_price_token: null,
      hot_price_ton: null,
      current_time: 48,
      hot_price: null,
      hot_date: null,
      state: false,
      open: false,
      hot: false,
      for_hot: false,
      currently_date: null,
      hot_date: null,
      id_default: null,
      id_hot: null,
      time_remain: null,
    },
    {
      id: 3,
      dis: null,
      default_price: null,
      default_price_token: null,
      hot_price_ton: null,
      current_time: 72,
      hot_price: null,
      hot_date: null,
      state: false,
      open: false,
      hot: false,
      for_hot: false,
      currently_date: null,
      hot_date: null,
      id_default: null,
      id_hot: null,
      time_remain: null,
    },
  ]);

  const [idTime, setIdTime] = useState(0);
  const findLastElement = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_KEY}/channel/last`
      );

      let first_id = result.data.id;
      let last_id_hot = 0;
      const arr_features = [first_id];
      for (let i = 1; i < 3; i++) {
        arr_features.push(first_id + i);
      }
      last_id_hot = arr_features[2] + 1;

      setLastId(last_id_hot);
      const times = [24, 48, 72];
      const new_price = priceSettings.map((element, index) => {
        element.id_default = arr_features[index];
        element.current_time = times[index];
        return element;
      });
      console.log("result", result);
      const result2 = min.map((element, index) => {
        return {
          id: arr_features[index],
          price: Number(element.price),
          hot: false,
        };
      });
      console.log("resultMIN", result2);
      setMin(result2);
      setPriceSettings(new_price);
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  const handleTypeTime = (key, id, current) => {
    const resul1 = condition.map((element) => {
      if (element.time == current) {
        element.state = false;
        return element;
      } else {
        return element;
      }
    });
    setCondition(resul1);
    const result = condition.map((element) => {
      if (element.time === key && element.state === false) {
        element.state = true;
        element.id = id;

        return element;
      } else if (element.id !== id && element.state === true) {
        return element;
      } else {
        element.state = false;
        element.id = null;
        return element;
      }
    });

    setCondition(result);
    setClickedHidden(!clickedHidden);
    setIdOne(id);
    updateCondition(id);
  };

  useEffect(() => {
    if (data.priceObjects.length > 0) {
      setIsNewPriceObjects(false);

      // Создаем копию priceSettings для работы
      let updatedPriceSettings = priceSettings.map((setting) => ({
        ...setting,
      }));
      let priceArr = [...data.priceObjects]; // Создаем копию data.priceObjects
      const findHotElement = (time) => {
        let finded_hot = null;
        let finded_for_hot = null;

        data.priceObjects.map((element) => {
          if (element.hot === true) {
            return (finded_hot = element.id);
          } else if (element.for_hot === true) {
            finded_for_hot = element.id;
          }
        });

        return { finded_hot, finded_for_hot };
      };
      const findCurrentTimeInServerData = (id) => {
        //доделать
        let find_time = null;
        data.priceObjects.map((element) => {
          if (element.id_default === id && element.hot === false) {
            return (find_time = element.time);
          }
        });
        return find_time;
      };

      const findStateHot = (id) => {
        let findId = false;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.hot === true) {
            return (findId = true);
          }
        });
        return findId;
      };
      const findStateForHot = (id) => {
        let findId = false;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.for_hot === true) {
            return (findId = true);
          }
        });
        return findId;
      };
      const findTime = (id) => {
        let findTime2 = null;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id)) {
            return (findTime2 = element.time);
          }
        });
        return findTime2;
      };
      const findDefaultPrice = (id) => {
        let findPrice = null;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.hot === false) {
            return (findPrice = Number(element.price));
          }
        });
        return findPrice;
      };
      const findDefaultPriceTON = (id) => {
        let findPrice = null;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.hot === false) {
            return (findPrice = Number(element.price_token));
          }
        });
        return findPrice;
      };
      const findHotPrice = (id) => {
        let findPrice = null;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.hot === true) {
            return (findPrice = Number(element.price));
          }
        });
        return findPrice;
      };
      const findHotPriceTON = (id) => {
        let findPrice = null;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.hot === true) {
            return (findPrice = Number(element.price_token));
          }
        });
        return findPrice;
      };

      const findIDHotDate = (id) => {
        let findHotDate = null;
        data.priceObjects.map((element) => {
          if (Number(element.id) === Number(id) && element.hot === true) {
            return (findHotDate = element.hot_date);
          }
        });
        return findHotDate;
      };
      const findTime0 = (time) => {
        let isTime = false;
        let idFind = null;
        data.priceObjects.map((element) => {
          if (element.time === time) {
            isTime = true;
            idFind = element.id;
          }
        });
        return { isTime, idFind };
      };
      let newCond = condition.map((element) => {
        const { idFind, isTime } = findTime0(element.time);
        element.state = isTime;
        element.id = Number(idFind);
        return element;
      });

      setCondition(newCond);

      let updatedPriceSettings1 = updatedPriceSettings.map((setting) => {
        // Находим элементы, не использующие индексы после удаления
        const defaultElement = priceArr.find((el) => el.hot === false);
        const hotElement = priceArr.find((el) => el.hot === true);

        if (defaultElement) {
          setting.id_default = Number(defaultElement.id);
          priceArr = priceArr.filter((el) => el.id !== defaultElement.id); // Удаляем использованный элемент
        } else {
          setting.id_default = null;
        }

        return setting;
      });
      const nahIdwin = updatedPriceSettings1.map((element) => {
        //id not need add ID not time
        const { finded_hot, finded_for_hot } = findHotElement();

        if (Number(finded_for_hot) === Number(element.id_default)) {
          return { ...element, id_hot: Number(finded_hot) };
        } else {
          return element;
        }
      });

      const hanIdwin1 = nahIdwin.map((element) => {
        element.hot = findStateHot(element.id_hot);
        element.for_hot = findStateForHot(element.id_default);
        return element;
      });
      const hanIdwin2 = hanIdwin1.map((element) => {
        element.current_time = findTime(element.id_default);
        return element;
      });

      const nahIWdin3 = hanIdwin2.map((element) => {
        element.default_price = findDefaultPrice(element.id_default);
        element.default_price_token = findDefaultPriceTON(element.id_default);
        element.hot_price = findHotPrice(element.id_hot);

        element.hot_date = findIDHotDate(element.id_hot);
        element.hot_price_ton = findHotPriceTON(element.id_hot);
        return element;
      });

      const newPriceSettings = nahIWdin3.map((setting) => {
        if (setting.default_price && setting.hot_price) {
          const discount =
            ((setting.default_price - setting.hot_price) /
              setting.default_price) *
            100;
          return { ...setting, dis: parseInt(discount) };
        }
        return setting;
      });

      // Устанавливаем новые настройки цен
      newPriceSettings.forEach((setting) => {
        if (setting.hot_date !== null) {
          setTimeIdSelected(setting.id_default);
          //const newDate = new Date(setting.hot_date)
        }
      });
      const sortedPriceSettings = sortPrice(newPriceSettings);

      const newSorted = assignIdsBasedOnTime(sortedPriceSettings);
      setPriceSettings(newSorted);

      function isEmpty(str) {
        return !str || 0 === str.length;
      }
      if (isEmpty(data.url_background_channel)) {
        setLinkImage("/uploads/276453346.png");
      } else {
        setLinkImage(data.url_background_channel);
      }

      //setDescription(data.desc_channel)
      setIsCanPublic(true);
    } else if (Number(data.priceObjects.length) === 0) {
      console.log("AAAAAAAAAAAAAAAA");
      findLastElement();
      const some = condition.map((element) => {
        if (element.time === 48) {
          element.state = true;
          return element;
        } else if (element.time === 72) {
          element.state = true;
          return element;
        } else {
          return element;
        }
      });
      setCondition(some);

      setIsNewPriceObjects(true);
      //setDescription(current_desc)
    }
  }, [data, location]);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileName(shortenFileName(file.name));
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("image", file);

      const token = await Cookies.get("token");
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}/upload`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setLinkImage(data.imagelink);
    }
  };

  const shortenFileName = useCallback((name) => {
    const maxLength = 20;
    if (name.length <= maxLength) {
      return name;
    }
    const start = name.slice(0, 10);
    const end = name.slice(-10);
    return `${start}...${end}`;
  }, []);

  const handleReload = () => {
    setDescription(data.desc_channel);
    setDescIsCustom(false);
  };
  const handleReloadWallpaper = () => {
    setLinkImage("/uploads/276453346.png");
  };

  const handleCostAdvertsChange = (e, id) => {
    const value = parseInt(e.target.value);
    if (value < 0) {
      return alert(`${t("noNegative")}`);
    }
    const result = priceSettings.map((element) => {
      if (element.id_default === id) {
        return { ...element, default_price: value };
      } else {
        return element;
      }
    });

    setIsUpdate(isUpdate + 1);
    setPriceSettings(result);

    const result2 = min.map((element) => {
      if (Number(element.id) === Number(id)) {
        return { ...element, price: Number(value) };
      } else {
        return element;
      }
    });
    setMin(result2);
  };
  const handleCostAdvertsChangeTON = (e, id) => {
    const value = parseInt(e.target.value);
    if (value < 0) {
      return alert(`${t("noNegative")}`);
    }
    const result = priceSettings.map((element) => {
      if (element.id_default === id) {
        return { ...element, default_price_token: value };
      }
      return element;
    });
    const newPriceSettings = result.map((setting) => {
      if (
        setting.default_price &&
        setting.hot_price &&
        setting.id_default === id
      ) {
        const discount =
          ((setting.default_price - setting.hot_price) /
            setting.default_price) *
          100;

        return { ...setting, dis: parseInt(discount) };
      } else {
        return setting;
      }
    });
    setIsUpdate(isUpdate + 1);
    setPriceSettings(newPriceSettings);
  };
  useEffect(() => {
    let count = 0;

    min.map((element) => {
      if (element.price) {
        count += 1;
      } else {
        count -= 1;
      }
    });
    let isTrue = false;
    if (category) {
      isTrue = category.map((element) => {
        if (element.lang && element.category) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (count >= 3 && isTrue[0]) {
      setIsCanPublic(true);
    } else {
      if (isNewPriceObjects === true) {
        setIsCanPublic(false);
      }
    }
  }, [min, category, selectedCategoryOrLang]);
  useEffect(() => {
    console.log("iscan", IsCanPublic, data.id);
  }, [IsCanPublic]);
  // const handleSave = () => {
  // 	const lastEnteredPrice = costAdverts[costAdverts.length - 1]
  // 	setSave(lastEnteredPrice)
  // }

  const handleRemoveChannel = () => {
    setChannel("");
    const some = async () => {
      try {
        const token = Cookies.get("token");
        const data7 = await axios.delete(
          `${process.env.REACT_APP_API_KEY}/channel/deletechannel`,
          {
            params: { id_channel: Number(data.id) },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    some();
  };

  const handleClickedHidden = (id) => {
    const result = priceSettings.map((el) => {
      if (el.id_default === id) return !clickedHidden;
    });
    setClickedHidden(result);
  };
  const handleOpen = (id) => {
    // if(isNewPriceObjects === true) {

    // }

    const result2 = priceSettings.map((element) => {
      if (element.id_default === id) {
        element.state = true;
        element.open = !element.open;
        return element;
      } else {
        element.state = false;
        element.open = false;
        return element;
      }
    });

    setPriceSettings(result2);
  };

  const updateCondition = (idOne) => {
    let findedTime1 = "";
    condition.map((element) => {
      if (element.state === true && element.id === idOne) {
        findedTime1 = element.time;
      }
    });

    let some = {};

    const result2 = priceSettings.map((element) => {
      if (element.id_default === idOne) {
        element.current_time = findedTime1;
        return element;
      } else {
        return element;
      }
    });

    setPriceSettings(result2);
  };

  useEffect(() => {
    const updateRemainingTime = () => {
      if (!priceSettings || !timeIdSelected) return;

      /**
       * @changes Для каждого из счетчика - своя функция
       **/
      const newData1 = priceSettings.map((element) => {
        if (
          element.id_default === timeIdSelected ||
          element.hot_date !== null
        ) {
          const now = new Date();
          const hotDate = new Date(element.hot_date); // Преобразуем строку в дату
          const distance = hotDate - now;

          if (distance < 0) {
            element.time_remain = "00:00:00"; // Обновляем поле time_remain
          } else {
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            element.time_remain = `${days > 0 ? days + "дн " : ""}${String(
              hours
            ).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
              seconds
            ).padStart(2, "0")}`;
          }
        }
        return element;
      });

      setPriceSettings(newData1);
    };

    const interval = setInterval(updateRemainingTime, 1000);
    updateRemainingTime();

    return () => clearInterval(interval);
  }, [timeIdSelected, isUpdate]);

  const handleAddHot = (id) => {
    const findAll = () => {
      let find = false;
      priceSettings.map((element) => {
        if (element.id_default !== id && element.hot === true) {
          find = true;
          return alert(`${t("deleteLastHot")}`);
        }
      });
      return find;
    };
    const result = findAll();
    if (result === true) {
      return;
    }

    function isEmpty(str) {
      return !str || 0 === str.length;
    }

    var date_cur = "";
    priceSettings.map((element) => {
      if (element.id_default === id) {
        date_cur = element.hot_date;
      }
    });
    const dateIsEmpty = isEmpty(date_cur);
    if (dateIsEmpty) {
      alert(`${t("inputDate")}`);
      return;
    }

    var currentDate = new Date();
    var inputDate = new Date(date_cur);
    let DataIsCorrectly = false;

    if (currentDate < inputDate) {
      DataIsCorrectly = true;
    } else {
      alert(`${t("dateNoToday")}`);
      DataIsCorrectly = false;
      return;
    }
    let some1 = false;

    ///тут должен быть id_hot не null
    priceSettings.map((element) => {
      if (
        (element.hot_price === null && element.id_default === id) ||
        (element.hot_price === "" && element.id_default === id) ||
        (isNaN(element.hot_price) && element.id_default === id)
      ) {
        alert(`${t("inputCost")}`);
        some1 = true;
        return;
      }
    });
    if (some1 === true) {
      return;
    }
    let some12 = null;
    priceSettings.map((element) => {
      if (element.id_default === id) {
        return (some12 = element);
      }
    });

    let current_Object = null;

    let some13 = null;

    if (some12.hot === true) {
      {
        toServerDeleteHot(some12.id_hot);
        some12.hot = false;
        some12.for_hot = false;
        some12.hot_date = null;
        some12.hot_price = null;
        some12.dis = null;

        some12.id_hot = null;
        some13 = some12;
      }
      setIsHot(!isHot);
    } else {
      some12.hot = true;
      some12.for_hot = true;
      some12.id_hot = some12.id_hot === null ? lastId : some12.id_hot;
      some12.hot_date = inputDate.toISOString();

      some13 = some12;
      setIsHot(false);
    }

    const ID = some13.id_default;
    const newArr = priceSettings.filter((element) => {
      if (element.id_default !== ID) {
        return element;
      }
    });

    const sortedPriceSettingsOne = sortPrice([...newArr, some13]);
    setPriceSettings(sortedPriceSettingsOne);
    //setPriceSettings([...newArr, some13])
  };
  const toServerDeleteHot = async (id) => {
    try {
      const token = Cookies.get("token");
      const url = `${process.env.REACT_APP_API_KEY}/channel/one?id_channel=${data.id}&id=${id}`;
      const result = await axios.delete(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddHotPriceTON = (e, id) => {
    const value5 = parseInt(e.target.value);
    if (value5 < 0) {
      return alert(`${t("noNegative")}`);
    }
    const newPriceSettings = priceSettings.map((setting) => {
      if (
        setting.hot_price_ton &&
        e.target.value &&
        setting.id_default === id
      ) {
        const discount =
          ((setting.hot_price_ton - e.target.value) / setting.default_price) *
          100;

        return { ...setting, dis: parseInt(discount) };
      } else {
        return setting;
      }
    });

    const value = parseInt(e.target.value);
    const result = newPriceSettings.map((element) => {
      if (element.id_default === id) {
        return { ...element, hot_price_ton: value };
      }
      return element;
    });
    const newSorted1 = sortPrice(result);
    setIsUpdate(isUpdate + 1);
    setPriceSettings(newSorted1);
  };

  const handleAddHotPrice = (e, id) => {
    const value5 = parseInt(e.target.value);
    if (value5 < 0) {
      return alert(`${t("noNegative")}`);
    }

    const value = parseInt(e.target.value);
    const result = priceSettings.map((element) => {
      if (element.id_default === id) {
        return { ...element, hot_price: value };
      }
      return element;
    });

    const newSorted1 = sortPrice(result);

    setIsUpdate(isUpdate + 1);
    setPriceSettings(newSorted1);
  };
  const some = useSelector((state) => state.selectedCatalog);
  const [checked, setChecked] = useState(false);
  const [checkedTon, setCheckedTon] = useState(false);
  const [checkedAuto, setCheckedAuto] = useState(false);
  const [checkedChannel, setCheckedChannel] = useState(false);
  const [checkedPush, setCheckedPush] = useState(false);

  useEffect(() => {
    setChecked(data.dont_add_piar_without_mark);
    setCheckedTon(data.can_buy_ton);
    setCheckedAuto(data.compute_ton_auto);
    // setCheckedChannel(data.)
  }, [data]);

  const handleChange = () => {
    setChecked(!checked);
  };
  const handleChangeTon = () => {
    setCheckedTon(!checkedTon);
  };
  const handleChangeAuto = () => {
    setCheckedAuto(!checkedAuto);
  };
  const handleChangeChannel = () => {
    setCheckedChannel(!checkedChannel);
  };
  const handleChangePush = () => {
    setCheckedPush(!checkedPush);
  };
  const handleToPublic = async () => {
    try {
      function isEmpty(str) {
        return !str || 0 === str.length || str === "Не выбрано";
      }
      let somePrice = 0;
      let hot_price = 0;
      let some_time = 0;
      priceSettings.map((element) => {
        if (
          element.default_price === 0 ||
          element.default_price === null ||
          isEmpty(element.default_price)
        ) {
          return alert(`${t("inputCost")}`);
        }
      });

      const result1 = priceSettings.map((element) => {
        if (element.hot === false) {
          return {
            ...element,
            id: element.id_default,
          };
        } else {
          return {
            ...element,
            id: element.id_hot,
          };
        }
      });

      const result = result1.map((element) => {
        if (element.hot === false || element.hot === true) {
          return {
            time: element.current_time,
            hot: false,
            for_hot: element.for_hot,
            price: Number(element.default_price),
            price_token: Number(element.default_price_token),
            hot_date: null,
            currently_date: null,
            id: element.id_default,
            //id_default: element.id_default,
          };
        }
      });

      let object2 = {};

      priceSettings.map((element) => {
        if (element.hot === true) {
          object2 = {
            time: element.current_time,
            hot: element.hot,
            for_hot: false,
            price: Number(element.hot_price),
            price_token: Number(element.hot_price_ton),
            hot_date: element.hot_date,
            currently_date: null,
            id: element.id_hot,
            //for_hot_fix
          };
        }
      });
      let state = false;
      const priceObjects1 = [...result, object2];
      priceObjects1.map((element) => {
        if (element.hot === true) {
          state = true;
        }
      });
      const result2 = priceObjects1.filter((obj) => {
        if (Object.keys(obj).length === 0) {
          console.log("Этот объект пустой");
        } else {
          console.log("Этот объект не пустой");
          return obj;
        }
      });
      //default_price_token
      result2.map((element) => {
        if (
          element.default_price === null ||
          element.default_price === 0 ||
          element.default_price === ""
        ) {
          return alert(`${t("inputCost")}`);
        }
      });

      let lang = "";
      let category = "";

      langAndCategory.map((element) => {
        if (element.id === data.id) {
          lang = element.lang;
          category = element.category;
        }
      });

      if (isEmpty(category) || isEmpty(lang)) {
        setCorrectlyData(true);
        return;
      }

      if (isEmpty(category) === false && isEmpty(lang) === false) {
        setCorrectlyData(false);
      }
      console.log("lang", lang);

      const token = Cookies.get("token");
      let findDefault = 0;
      priceObjects1.map((element) => {
        if (element.time === 24 && element.hot === false) {
          findDefault = element.price;
        }
      });

      const UpdateData = async () => {
        try {
          const data6 = await axios.put(
            `${process.env.REACT_APP_API_KEY}/channel/one?id=${data.id}`,
            {
              channel: {
                id: data.id,
                hot_state: state,
                default_price: findDefault,
                language: lang,
                Category: category,
                ERR: data.ERR,
                is_published: !isActive.is_published,
                is_pause: !isActive.is_pause,
                is_kicked: isActive.is_kicked,
                is_moderation: isActive.is_moderation,

                can_buy_ton: checkedTon,
                compute_ton_auto: checkedAuto,
                dont_add_piar_without_mark: checked,

                desc_channel: description,
                desc_is_custom: descIsCustom,
                url_background_channel: linkImage
                  ? linkImage
                  : "/uploads/276453346.png",
              },
              channelPriceObjects: result2,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          return data6;
        } catch (error) {
          console.log("error", error);
        }
      };
      const resultOne = await UpdateData();
      console.log(resultOne);
      if (resultOne.status === 200) {
        let is_activeOne = resultOne.data.newUpdatedChannel.is_published;
        let is_pauseOne = resultOne.data.newUpdatedChannel.is_pause;
        let is_kickedOne = resultOne.data.newUpdatedChannel.is_kicked;
        let is_moderationOne = resultOne.data.newUpdatedChannel.is_moderation;

        setIsActive({
          is_published: is_activeOne,
          is_pause: is_pauseOne,
          is_kicked: is_kickedOne,
          is_moderation: is_moderationOne,
        });
        setDefaultPrice(resultOne.data.default_price);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const installDate = (date, id) => {
    const priceSettings2 = priceSettings.map((element) => {
      if (element.id_default === id) {
        console.log("date.toISOString()", date.toISOString());
        element.hot_date = date.toISOString();
        setIsUpdate(isUpdate + 1);
        //element.time_remain = date;
        return element;
      } else {
        return element;
      }
    });
    setPriceSettings(priceSettings2);
  };

  const handleDisplay = (arg) => {
    setIsDisplay(!arg);
    if (arg !== false) {
      displayRef.current.style = "display:none";
      displayRef2.current.style = "display:none";
      displayRef3.current.style = "display:none";
    } else {
      displayRef.current.style = "display:flex";
      displayRef2.current.style = "display:flex";
      displayRef3.current.style = "display:flex";
    }
  };
  const getStatusClass = () => {
    if (defaultPrice === 0 && isActive.is_published === false) {
      return style.btnDraft;
    } else if (isActive.is_published) {
      return style.btnIsPublish;
    } else if (isActive.is_pause) {
      return style.btnIsPause;
    } else if (isActive.is_moderation && isActive.is_published === false) {
      return style.isModerate;
    } else if (isActive.is_kicked && isActive.is_published === false) {
      return style.btnDraft;
    } else {
    }
  };

  const getStatusText = () => {
    if (defaultPrice === 0 && isActive.is_published === false) {
      return `${t("dontConfig")}`;
    } else if (isActive.is_pause) {
      return `${t("onPause")}`;
    } else if (isActive.is_moderation && isActive.is_published === false) {
      return `${t("moderate")}`;
    } else if (isActive.is_kicked === true) {
      return `${t("kick")}`;
    } else if (isActive.is_published) {
      return `${t("published")}`;
    }
  };
  const getButtonClass = () => {
    if (
      isActive.is_pause === true &&
      isActive.is_published === false &&
      IsCanPublic
    ) {
      return style.btnPublishGreen;
    } else if (isActive.is_pause === false && isActive.is_published === true) {
      return style.btnOnPause;
    } else if (IsCanPublic === false) {
      return style.btnPublish;
    }
  };

  const getButtonText = () => {
    if (isActive.is_pause) {
      return `${t("public")}`; // 'Опубликовать'
    } else {
      return `${t("onPause2")}`; // 'На паузе'
    }
  };

  return (
    <div className={isNotActive === false && `${style.wrapperEditor}`}>
      {isNotActive === false ? (
        <span className={isNotActive === false && `${style.wrapperEditorText}`}>
          {t("addingBotLikeAdmin")}
        </span>
      ) : (
        ""
      )}
      <div className={style.editor}>
        {/* ======= Header ======= */}
        <div className={style.headerEditor}>
          <div className={style.logoEditor}>
            <button
              className={
                !isDisplay
                  ? style.wrapperDisplayBtn
                  : style.wrapperDisplayBtnRotate
              }
              onClick={() => handleDisplay(isDisplay)}
            >
              <img src={!isDisplay ? substract2 : substract} />
            </button>
            <img
              src={`${
                process.env.REACT_APP_API_KEY + "/bot/" + data.url_Image_Channel
              }`}
              alt="iconAdvix"
            />
            <span className={style.channelName}>{data.name_channel}</span>
            <div className={style.headerBtn}>
              <button className={getStatusClass()}>{getStatusText()}</button>
            </div>
            {/* <div className={style.headerBtn}>
              {
                <button
                  className={`${
                    defaultPrice === 0 && isActive.is_published === false
                      ? style.btnDraft
                      : isActive.is_pause === true
                      ? style.btnIsPause
                      : style.btnIsPublish
                  }`}
                >
                  {
                    defaultPrice === 0 && isActive.is_published === false
                      ? `${t("dontConfig")}` //'Не настроен'
                      : isActive.is_pause === true
                      ? `${t("onPause")}` //'На паузе'
                      : `${t("published")}` //'Опубликован'
                  }
                </button>
              }
            </div> */}
          </div>
          {correcltyData ? (
            <div>
              <div className={style.imgExclamation}>
                <span className={style.popupExcl}>{t("specifyCategory")}</span>
                <img src={iconExclamation} alt="!" />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={style.headerBtn}>
            {/* <button
              onClick={handleToPublic}
              className={`${
                //
                isActive.is_pause === true &&
                isActive.is_published === false &&
                IsCanPublic === true
                  ? style.btnPublishGreen
                  : IsCanPublic === false
                  ? style.btnPublish
                  : style.btnOnPause
              }`}
            >
              {isActive.is_pause === true
                ? `${t("public")}`
                : `${t("onPause2")}`}
            </button> */}
            <button onClick={handleToPublic} className={getButtonClass()}>
              {getButtonText()}
            </button>
          </div>
        </div>
        <div ref={displayRef3} className={style.wrapperNewBlock}>
          <div className={style.paramsChannelOne}>
            <div className={style.paramsContent}>
              {/* ======= DROP DOWN ============== */}
              <div className={style.item}>
                <span className={style.titleEditorItems}>{t("contact")}</span>

                <DropDown2 id={id_some} />
              </div>
              <div className={style.inderlineFirst}></div>
              <div className={style.item}>
                <span className={style.titleEditorItems}>
                  {t("categoryChannel")}
                </span>

                <DropDown2 id={id_some} />
              </div>

              <div className={style.item}>
                <span className={style.titleEditorItems}>
                  {t("langChannel")}
                </span>

                <DropDownLang2
                  id={data.id}
                  default_arg={data.language}
                  args={langList}
                />
              </div>
            </div>
          </div>

          <div className={style.descEditor}>
            <span className={style.titleEditorItems}>{t("descChannel")}</span>
            {/* <button className={style.reloadBtn} onClick={handleReload}>
							<img src={icoReload} alt='iconReload' className={style.imgIcon} />
						</button> */}
            <textarea
              className={style.descriptionOne}
              value={description}
              onChange={(e) => {
                setDescIsCustom(true);
                setDescription(e.target.value);
              }}
              maxLength={50}
              cols="5"
              rows="5"
            ></textarea>
            <div className={style.item}>
              <span className={style.titleEditorItems}>
                {t("coverChannel")}
              </span>
              <button className={style.btnDownload} onClick={handleButtonClick}>
                {!image ? `${t("download")}` : fileName}
                {image && (
                  <img src={image} alt="cover" className={style.previewCover} />
                )}
              </button>

              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className={style.forms}>
            <span className={style.titleEditorItems}>{t("settings")} </span>
            <form className={style.wrapperForm}>
              <label className={style.customCheckbox} htmlFor="">
                <input
                  //className={checked ? 'checked' : ''}
                  type="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
                {t("noAdverWOMark")}
              </label>
            </form>
            <form className={style.wrapperForm} action="">
              <label className={style.customCheckbox} htmlFor="">
                <input
                  //className={style.checkboxInput}
                  type="checkbox"
                  checked={checkedTon}
                  onChange={handleChangeTon}
                />

                {t("canPayTon")}
              </label>
            </form>
            <form className={style.wrapperForm} action="">
              <label className={style.customCheckbox} htmlFor="">
                <input
                  //className={style.checkboxInput}
                  type="checkbox"
                  checked={checkedAuto}
                  onChange={handleChangeAuto}
                />

                {t("autoTon")}
              </label>
            </form>
            <div className={style.underline}></div>
            <form className={style.wrapperForm} action="">
              <label className={style.customCheckbox} htmlFor="">
                <input
                  //className={style.checkboxInput}
                  type="checkbox"
                  checked={checkedChannel}
                  onChange={handleChangeChannel}
                />

                {t("pushChannel")}
              </label>
            </form>
            <form className={style.wrapperForm} action="">
              <label className={style.customCheckbox} htmlFor="">
                <input
                  //className={style.checkboxInput}
                  type="checkbox"
                  checked={checkedPush}
                  onChange={handleChangePush}
                />

                {t("pushAdvert")}
              </label>
            </form>
          </div>
        </div>
        <div ref={displayRef} className={style.editorContent}>
          {/* ======= Block 1 paramsChannel ======= */}

          <div className={style.costAdvertising}>
            <span className={style.paramsHeader}>{t("costAdvert")}</span>
            {priceSettings.map((el, index) => {
              return (
                <div
                  className={`${el.id !== 3 ? "" : style.noBorderBottom}`}
                  key={el.id}
                >
                  <div className={style.itemAdv}>
                    <span className={style.number}>{el.id}</span>
                    <div className={style.advertisingItem}>
                      <div
                        onClick={() => handleOpen(el.id_default)}
                        className={
                          !el.default_price
                            ? style.btnSale
                            : style.btnSaleDefault
                        }
                      >
                        <div className={style.numWrapper}>
                          {el.current_time !== null &&
                            (el.current_time === 24
                              ? "1/24"
                              : el.current_time === 48
                              ? "2/48"
                              : el.current_time === 72
                              ? "4/72"
                              : el.current_time === 0
                              ? `${t("native")}`
                              : el.current_time === 1
                              ? `${t("repost")}`
                              : el.current_time === 2
                              ? `${t("withoutRemove")}`
                              : `${t("unknownValue")}`)}
                        </div>
                        {
                          <React.Fragment key={el.id}>
                            {el.open === true &&
                              el.state === true &&
                              el.current_time !== 24 && (
                                <ul className={style.wrapperTime}>
                                  {condition.map((conditionElement) => {
                                    return (
                                      conditionElement.state === false && (
                                        <li
                                          key={conditionElement.time}
                                          className={style.liTime}
                                          onClick={() =>
                                            handleTypeTime(
                                              conditionElement.time,
                                              el.id_default,
                                              el.current_time
                                            )
                                          }
                                        >
                                          {conditionElement.time === 24 &&
                                            "1/24"}
                                          {conditionElement.time === 48 &&
                                            "2/48"}
                                          {conditionElement.time === 72 &&
                                            "4/72"}
                                          {conditionElement.time === 0 &&
                                            `${t("native")}`}
                                          {conditionElement.time === 1 &&
                                            `${t("repost")}`}
                                          {conditionElement.time === 2 &&
                                            `${t("withoutRemove")}`}
                                        </li>
                                      )
                                    );
                                  })}
                                </ul>
                              )}
                            {el.current_time !== 24 && (
                              <button
                                onClick={() =>
                                  handleClickedHidden(el.id_default)
                                }
                                className={
                                  el.open
                                    ? `${style.polygonDropDown}`
                                    : `${style.polygonDropDownRotate}`
                                }
                              ></button>
                            )}
                          </React.Fragment>
                        }
                      </div>

                      <label
                        className={
                          !!el.default_price
                            ? style.labelInput
                            : style.labelInputRed
                        }
                        htmlFor=""
                      >
                        <input
                          min={0}
                          type="number"
                          id="costAdver"
                          className={style.inputCost}
                          placeholder={t("enterPrice")}
                          value={el.default_price}
                          onChange={(e) =>
                            handleCostAdvertsChange(e, el.id_default)
                          }
                        />
                        <img
                          className={style.valute}
                          src={rub}
                          alt=""
                          srcset=""
                        />
                      </label>
                      <label className={style.labelInput}>
                        <div
                          className={
                            checkedTon === false
                              ? style.blurInput
                              : style.blurNone
                          }
                        ></div>
                        <input
                          min={0}
                          type="number"
                          id="costAdver"
                          disabled={checkedTon === false ? true : false}
                          className={style.inputCost}
                          placeholder={t("enterPrice")}
                          value={el.default_price_token}
                          onChange={(e) =>
                            handleCostAdvertsChangeTON(e, el.id_default)
                          }
                        />
                        <img
                          className={style.valute}
                          src={ton}
                          alt=""
                          srcset=""
                        />
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* ====== element 1 block 3 ====== */}
          <div className={style.salesBlock}>
            <span className={style.paramsHeader}>
              {t("createHot")} &#128293;
            </span>
            {priceSettings.map((el) => (
              <div className={style.salesContent} key={el.id}>
                <div
                  className={
                    el.default_price
                      ? el.hot
                        ? style.itemSaleRed
                        : isHot
                        ? style.itemSaleHot
                        : style.itemSale
                      : style.itemSale
                  }
                >
                  <label
                    className={
                      el.default_price
                        ? el.hot
                          ? style.labelInputHotRedEn
                          : isHot
                          ? style.labelInputHotEn
                          : style.labelInputHot
                        : style.labelInputHot
                    }
                  >
                    <input
                      type="number"
                      id="costAdver"
                      min={0}
                      className={style.inputCost}
                      placeholder={t("enterPrice")}
                      value={el.hot_price}
                      onChange={(e) => handleAddHotPrice(e, el.id_default)}
                    />
                    <img className={style.valute} src={rub} alt="" srcset="" />
                  </label>
                  <label
                    className={
                      el.default_price
                        ? el.hot
                          ? style.labelInputHotRedEn
                          : isHot
                          ? style.labelInputHotEn
                          : style.labelInputHot
                        : style.labelInputHot
                    }
                    htmlFor=""
                  >
                    <div
                      className={
                        checkedTon === false ? style.blurInput : style.blurNone
                      }
                    ></div>
                    <input
                      type="number"
                      id="costAdver"
                      min={0}
                      disabled={checkedTon === false ? true : false}
                      className={style.inputCost}
                      placeholder={t("enterPrice")}
                      value={el.hot_price_ton}
                      onChange={(e) => handleAddHotPriceTON(e, el.id_default)}
                    />
                    <img className={style.valute} src={ton} alt="" srcset="" />
                  </label>

                  {/**
                   * @changes Для отдельного счетчика - свой html
                   **/}
                  {
                    <>
                      <div
                        className={
                          el.default_price
                            ? el.hot
                              ? style.saleElementEnRed
                              : isHot
                              ? style.saleElementEn
                              : style.saleElement
                            : style.saleElement
                        }
                      >
                        <DatePicker
                          selected={el.hot_date}
                          onChange={(date) => {
                            setTimeIdSelected(el.id_default);
                            installDate(date, el.id_default);
                            setIdTime(el.id_default);
                            //setStartDate1(date)
                          }}
                          dateFormat="dd.MM.yyyy"
                          className={style.datePicker}
                          customInput={
                            <DateInput className={style.dateInput} />
                          }
                        />
                      </div>
                      <div
                        className={
                          el.default_price
                            ? el.hot
                              ? style.saleElementEnRed
                              : isHot
                              ? style.saleElementEn
                              : style.saleElement
                            : style.saleElement
                        }
                      >
                        {el.time_remain}
                      </div>
                    </>
                  }
                  <button
                    onClick={() => handleAddHot(el.id_default)}
                    // className={
                    //   el.default_price
                    //     ? el.hot
                    //       ? style.btnStartRed
                    //       : style.btnStartYellow
                    //     : style.btnStart
                    // }
                    className={
                      el.default_price
                        ? el.hot
                          ? style.btnStartRed
                          : isHot
                          ? style.btnStartYellow
                          : style.btnStart
                        : style.btnStart
                    }
                  >
                    {el.hot ? `${t("stop")}` : `${t("start")}`}
                  </button>
                </div>
                <div className={style.horizontalLine}></div>
              </div>
            ))}
          </div>
        </div>

        <div ref={displayRef2} className={style.wrapperBtnDelete}>
          <button className={style.btnDelete} onClick={handleRemoveChannel}>
            {t("delete")}
          </button>
        </div>
      </div>
    </div>
  );
};
