/** @format */

import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import style from "./CopyLink.module.scss";
import imgCopy from "../../../assets/icons/copy.svg";

const CopyLink = () => {
  return (
    <>
      <CopyToClipboard className={style.wrapperCopyLink} text="@advixbot">
        <button className={style.btnCopyLink}>
          <img className={style.imgCopyLink} src={imgCopy} alt="copy" />
        </button>
      </CopyToClipboard>
    </>
  );
};

export default CopyLink;
