/** @format */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dropdownArrow from '../../assets/sort-amount-down1.svg'
import dropdownArrowUp from '../../assets/sort-amount-up.svg'
import { setFetchDataChannel } from '../../redux/reducers/ChannelDataSlice'
import { setSort } from '../../redux/reducers/SortingSlice'
import style from './DropDown.module.scss'

const DropDown = ({ default_arg, args }) => {
	// const { t } = useTranslation();
	const [clicked, setClicked] = useState(false)
	const [clickedHidden, setClickedHidden] = useState(false)
	const [order, setOrder] = useState(true)
	const refsLi = useRef([])
	let catMenu = useRef()
	let btnOrder = useRef() // реф для кнопки clickedBtnOrder

	const [selectedElement, setSelectedElement] = useState('')
	const [selectedElementIndex, setSelectedElementIndex] = useState(null)
	const { sort } = useSelector(state => state.sortData)
	const dispatch = useDispatch()
	const { channels, status } = useSelector(state => state.channelData)

	useEffect(() => {
		let handler = e => {
			if (
				!catMenu.current.contains(e.target) &&
				!btnOrder.current.contains(e.target)
			) {
				setClickedHidden(false)
			}
		}

		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	}, [])

	const some = useCallback(
		arg => {
			switch (arg) {
				case 'Подписчики':
					console.log('local')
					console.log(order)
					if (order === true) {
						console.log('asc', channels)
						const arr = [...channels].sort(
							(a, b) => b.count_subscribers - a.count_subscribers
						)
						console.log('arr', arr)
						dispatch(setFetchDataChannel(arr))
					}
					if (order === false) {
						console.log('desc', channels)
						const arr = [...channels].sort(
							(a, b) => a.count_subscribers - b.count_subscribers
						)
						console.log('arr8', arr)
						dispatch(setFetchDataChannel(arr))
					}
					break
				case 'Рейтинг':
					console.log('position')
					console.log(order)
					if (order === true) {
						console.log('asc', channels)
						const arr = [...channels].sort((a, b) => b.position - a.position)
						console.log('arr', arr)
						dispatch(setFetchDataChannel(arr))
					}
					if (order === false) {
						console.log('desc', channels)
						const arr = [...channels].sort((a, b) => a.position - b.position)
						console.log('arr8', arr)
						dispatch(setFetchDataChannel(arr))
					}
					break
				case 'Вовлеченность (ERR)':
					console.log('ERR')
					console.log(order)
					if (order === true) {
						console.log('asc', channels)
						const arr = [...channels].sort(
							(a, b) =>
								parseFloat(Math.round(b.ERR)) - parseFloat(Math.round(a.ERR))
						)
						console.log('arr', arr)
						dispatch(setFetchDataChannel(arr))
					}
					if (order === false) {
						console.log('desc', channels)
						const arr = [...channels].sort(
							(a, b) =>
								parseFloat(Math.round(a.ERR)) - parseFloat(Math.round(b.ERR))
						)
						console.log('arr8', arr)
						dispatch(setFetchDataChannel(arr))
					}
					break
				case 'CPM':
					console.log('CPM')
					console.log(order)
					if (order === true) {
						console.log('asc', channels)
						const arr = [...channels].sort(
							(a, b) =>
								Math.round(parseInt(b.CPM)) - Math.round(parseInt(a.CPM))
						)
						console.log('arr', arr)
						dispatch(setFetchDataChannel(arr))
					}
					if (order === false) {
						console.log('desc', channels)
						const arr = [...channels].sort(
							(a, b) =>
								Math.round(parseInt(a.CPM)) - Math.round(parseInt(b.CPM))
						)
						console.log('arr8', arr)
						dispatch(setFetchDataChannel(arr))
					}
					break
				case 'Стоимость':
					console.log('CPM')
					console.log(order)
					if (order === true) {
						console.log('asc', channels)
						const arr = [...channels].sort(
							(a, b) => parseInt(b.default_price) - parseInt(a.default_price)
						)
						console.log('arr', arr)
						dispatch(setFetchDataChannel(arr))
					}
					if (order === false) {
						console.log('desc', channels)
						const arr = [...channels].sort(
							(a, b) => parseInt(a.default_price) - parseInt(b.default_price)
						)
						console.log('arr8', arr)
						dispatch(setFetchDataChannel(arr))
					}
					break
				case 'Просмотры на пост':
					console.log('Просмотры на пост')
					console.log(order)
					if (order === true) {
						console.log('asc', channels)
						const arr = [...channels].sort(
							(a, b) => parseInt(b.views) - parseInt(a.views)
						)
						console.log('arr', arr)
						dispatch(setFetchDataChannel(arr))
					}
					if (order === false) {
						console.log('desc', channels)
						const arr = [...channels].sort(
							(a, b) => parseInt(a.views) - parseInt(b.views)
						)
						console.log('arr8', arr)
						dispatch(setFetchDataChannel(arr))
					}
					break

				default:
					break
			}
		},
		[channels, order, dispatch]
	)

	useEffect(() => {
		if (selectedElement !== null && status === 'success') {
			some(selectedElement)
		}
	}, [selectedElement, status])

	const handleClickedElement = (element, index) => {
		setClicked(true)

		// Если клик происходит на уже выбранный элемент, сбрасываем его состояние
		if (selectedElementIndex === index) {
			setSelectedElement('')
			dispatch(setSort({ ...sort, name: '', is_sort: false }))

			setSelectedElementIndex(null)
			setClicked(false)
		} else {
			dispatch(setSort({ ...sort, name: element, is_sort: true }))
			console.log('domer', element)
			some(element)

			setSelectedElement(element)
			setSelectedElementIndex(index)
		}
		handleClickedHidden()
	}

	const handleClickedHidden = () => {
		setClickedHidden(!clickedHidden)
	}

	const clickedBtnOrder = order => {
		const newOrder = !order ? 'desc' : 'asc'
		setOrder(!order)

		dispatch(setSort({ ...sort, order: newOrder }))
	}

	return (
		<div className={style.wrapperRootDropDown}>
			<button
				className={style.btnOrderStyle}
				ref={btnOrder}
				onClick={() => clickedBtnOrder(order)}
			>
				<img
					className={style.blockArrowImg}
					src={order ? `${dropdownArrow}` : `${dropdownArrowUp}`}
					alt='order btn'
				/>
			</button>
			<div ref={catMenu} className={style.wrapperDropDown}>
				<button className={style.btnSort} onClick={handleClickedHidden}>
					{!clicked ? default_arg : selectedElement}
					<div
						className={
							clickedHidden
								? `${style.polygonDropDown}`
								: `${style.polygonDropDownRotate}`
						}
					></div>
				</button>
				{clickedHidden && (
					<ul>
						{args.map((element, index) => (
							<li
								key={index}
								ref={el => (refsLi.current[index] = el)}
								onClick={() => {
									handleClickedElement(element, index)
								}}
								style={{
									backgroundColor:
										selectedElementIndex === index ? '#00a5e9' : 'transparent',
									color: selectedElementIndex === index ? '#fff' : '#99a8b2',
									'&:hover': { backgroundColor: '#00a5e9', color: '#fff' },
								}}
							>
								{element}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	)
}

export default DropDown
