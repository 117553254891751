import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	count: 0,
}

const paginationButtonSlice = createSlice({
	name: 'count',
	initialState,
	reducers: {
		setCountPagination(state, action) {
			state.count = action.payload
		},
		deleteCountPagination(state) {
			state.count = {}
		},
	},
})
export const { setCountPagination, deleteCountPagination } =
	paginationButtonSlice.actions

export default paginationButtonSlice.reducer
