/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import earth from "../../../assets/header/earth.svg";
import earthWhite from "../../../assets/header/earthWhite1.svg";
import style from "./SwitchLang.module.scss";

const SwitchLang = () => {
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(false);
  const menuRef = useRef(null);

  const [language, setLanguage] = useState(() => {
    if (typeof window !== "undefined") {
      const lang = window.localStorage.getItem("language");
      return lang ? lang : "ru";
    } else {
      return "ru";
    }
  });
  const changeLanguage_ = (language) => {
    setLanguage(language);
  };
  const handleChangeLang = () => {
    setLang(!lang);
    console.log("lang ", lang);
  };
  const handleOpen = () => {
    setOpen(!open);
    console.log(open);
  };
  const { t, i18n } = useTranslation();
  const { theme } = useSelector((state) => state.theme);
  useEffect(() => {
    if (i18n.changeLanguage) {
      i18n
        .changeLanguage(language === "en" ? "en" : "ru")
        .then(() => {
          if (typeof window !== "undefined") {
            window.localStorage.setItem("language", language);
          }
        })
        .catch((error) => {
          console.error("Failed to change language:", error);
        });
    } else {
      console.error("changeLanguage function is not available");
    }
  }, [language, i18n]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        htmlFor="lang"
        className={style.switchLangWrapper}
        onClick={handleOpen}
        ref={menuRef}
      >
        {theme ? (
          <img src={earth} className={style.imgEarth} alt="earth" />
        ) : (
          <img src={earthWhite} className={style.imgEarth} alt="earth" />
        )}
        <div className={style.lableLang}>{language === "en" ? "EN" : "RU"}</div>
        <div className={open === true ? style.dropDown : style.DropDown2}>
          <ul>
            <li
              className={language === "ru" ? style.wrapperBlue : style.nonBlue}
              onClick={() => changeLanguage_("ru")}
            >
              RU
            </li>
            <li
              className={language === "en" ? style.wrapperBlue : style.nonBlue}
              onClick={() => changeLanguage_("en")}
            >
              EN
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default SwitchLang;
