/** @format */

import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { themeReducer } from "../../../redux/reducers/ThemeSlice";
import style from "./Switcher.module.scss";
export const SwitchButton = () => {
  const { theme } = useSelector((state) => state.theme);

  const dispatch = useDispatch();
  const handleChange = () => {
    dispatch(themeReducer(!theme));
    Cookies.set("theme", `${!theme}`);
  };

  return (
    <>
      <label
        htmlFor="switch"
        className={style.switchWrapper}
        style={theme ? {} : { backgroundColor: "#262629" }}
      >
        <input
          type="checkbox"
          id="switch"
          className={style.round}
          checked={!theme}
          onChange={handleChange}
        />
      </label>
    </>
  );
};
