import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './Footer.module.scss'

function Footer() {
	const currentYear = new Date().getFullYear()
	const { t } = useTranslation()

	return (
		<footer className={style.footerWrap}>
			<div className={style.footerWrapper}>
				<span className={style.titleFooter}>{t('important')}</span>
				<div className={style.links}>
					<a href='#'>{t('termsOfUse')}</a>
					<a href='#'>{t('privacyPolicy')}</a>
					<span>&copy; {currentYear}</span>
				</div>
			</div>

			<div className={style.footerWrapper}>
				<span className={style.titleFooter}>{t('Advix')}</span>
				<div className={style.links}>
					<a href='#'>{t('catalogFooter')}</a>
					<a href='#'>{t('knowledgeBase')}</a>
					<a href='#'>{t('partners')}</a>
					<span>support@domain.ru</span>
				</div>
			</div>

			<div className={style.footerWrapper}>
				<span className={style.titleFooter}>{t('telegram')}</span>
				<div className={style.links}>
					<a href='https://t.me/advixbot'>{t('notificationBot')}</a>
					<a href='https://t.me/advixnews'>{t('newsChannel')}</a>
					<a href='https://t.me/advixchat'>{t('talkingAboutService')}</a>
					<a href='https://t.me/advixsupportbot'>{t('support')}</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer
