import React from "react"
import ContentLoader from "react-content-loader"
import {useSelector} from 'react-redux'

const LoaderChannelComponent = (props) =>{
  const {theme} = useSelector(state => state.theme)
  return  (
    <ContentLoader 
      speed={4}
      width={1800}
      height={1156}
      viewBox="0 0 112.5rem 72.25rem"
      backgroundColor={theme?'#f3f3f3':"#404040"}
      foregroundColor={theme ?'#ffffff': '#646464'}
      {...props}
    >
      <rect x="0rem" y="1.875rem" rx=".9375rem" ry=".9375rem" width="14.75rem" height="2.9375rem" /> 
      <rect x="71rem" y="1.875rem" rx=".9375rem" ry=".9375rem" width="18.75rem" height="2.9375rem" /> 
      <rect x="58.75rem" y="3.125rem" rx=".25rem" ry=".25rem" width="11.25rem" height=".9375rem" /> 
      <rect x="0rem" y="53.125rem" rx=".9375rem" ry=".9375rem" width="9.6875rem" height="2.75rem" /> 
      <rect x="0rem" y="7.375rem" rx=".9375rem" ry=".9375rem" width="89.8125rem" height="43.75rem" />
    </ContentLoader>
)}

export default LoaderChannelComponent

