/** @format */

import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import done from '../../assets/done.svg'
import done2 from '../../assets/done2.svg'
import iconSearch from '../../assets/search.svg'
import { setCatalogArray } from '../../redux/reducers/SelectedCatalogArrayProfileSlice'
import { setCategoryOrLang } from '../../redux/reducers/SelectedCategoriesSlice'
import style from '../DropDownFilt2/DropDownFilt.module.scss'
const DropDownLang = ({ default_arg, id }) => {
	const { langAndCategory } = useSelector(state => state.categoryOrLangData)
	const [name, setName] = useState('Не выбрано')
	const [lastElement, setLastElement] = useState(null)
	const dispatch = useDispatch()
	const [inputData, setInputData] = useState(null)
	const fetchLanguage = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_KEY}/languages/all`
			)
			const newArr = data.result.map(element => {
				return element.name_lang
			})
			setArgs(newArr)
		} catch (error) {
			console.log(error)
		}
	}
	const [args, setArgs] = useState([])
	useEffect(() => {
		fetchLanguage()
	}, [])
	// useEffect(() => {
	// 	if (default_arg === '') {
	// 		setSelectedElement(['Не выбрано'])
	// 	} else {
	// 		setSelectedElement([default_arg])
	// 	}
	// }, [])
	useEffect(() => {
		console.log('langAndCategory', langAndCategory, id)
		langAndCategory.map(element => {
			if (Number(element.id) === Number(id) && element.lang !== '') {
				console.log('element.id', element.id, element.lang)
				setName(element.lang)
			}
		})
	}, [langAndCategory])
	useEffect(() => {
		setSelectedElement([name])
	}, [name])

	const [clicked, setClicked] = useState(false)
	const [clickedHidden, setClickedHidden] = useState(false)

	const refsLi = useRef([])
	let catMenu = useRef()

	const [selectedElement, setSelectedElement] = useState(['Не выбрано'])
	const { selectedCategoryOrLang } = useSelector(
		state => state.selectedCategoryOrLangData
	)
	const [selectedElementIndex, setSelectedElementIndex] = useState(null)

	useEffect(() => {
		let handler = e => {
			if (!catMenu.current.contains(e.target)) {
				setClickedHidden(false)
			}
		}

		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	})

	const handleClickedElement = (element, index) => {
		setClicked(true)
		if (selectedElementIndex === index) {
			setSelectedElement([''])
			dispatch(
				setCategoryOrLang({ ...selectedCategoryOrLang, lang: 'Не выбрано' })
			)
			let some = langAndCategory
			let some1 = some.map(element1 => {
				if (element1.id === id) {
					return { ...element1, lang: 'Не выбрано' }
				} else {
					return element1
				}
			})
			dispatch(setCatalogArray(some1))
			setSelectedElementIndex(null)
			setClicked(false)
		} else {
			setSelectedElement([element])
			setSelectedElementIndex(index)

			// dispatch(setCategoryOrLang({ ...selectedCategoryOrLang, lang: element }));
			let some = langAndCategory
			let some1 = some.map(element1 => {
				if (element1.id === id) {
					return { ...element1, lang: element }
				} else {
					return element1
				}
			})
			dispatch(setCatalogArray(some1))
		}
		handleClickedHidden()
	}

	const handleClickedHidden = () => {
		setClickedHidden(!clickedHidden)
	}

	const handleReload = () => {
		setSelectedElement(['Не выбрано'])
		setSelectedElementIndex(null)
		let some = langAndCategory
		let some1 = some.map(element1 => {
			if (element1.id === id) {
				return { ...element1, lang: 'Не выбрано' }
			} else {
				return element1
			}
		})
		dispatch(setCatalogArray(some1))
		dispatch(
			setCategoryOrLang({ ...selectedCategoryOrLang, lang: 'Не выбрано' })
		)
		setClicked(false)
	}
	const handleOnChange = e => {
		//setSelectedElement(e.target.value)
		setInputData(e.target.value)
		if (e.target.value === 'Не выбрано') {
			fetchLanguage()
		} else if (e.target.value === '') {
			fetchLanguage()
		} else {
			const filteredArgs = args.filter(arg =>
				arg.toLowerCase().includes(e.target.value.toLowerCase())
			)
			setArgs(filteredArgs)
		}
	}

	const findSelectedElement = (arr, element) => {
		let isFind = false

		arr.map(el => {
			if (el === element) {
				isFind = true
			}
		})
		return isFind
	}
	useEffect(() => {
		let handler = e => {
			if (!catMenu.current.contains(e.target)) {
				setClickedHidden(false)
			}
		}

		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	})

	return (
		<div className={style.wrapperRootDropDown}>
			<div ref={catMenu} className={style.wrapperDropDown}>
				<section
					className={
						selectedElement[0] !== 'Не выбрано'
							? style.btnSort
							: style.btnSortTrue
					}
					onClick={() => handleClickedHidden()}
				>
					{selectedElement.map(element => (
						<article className={element !== 'Не выбрано' && style.wrapperBlock}>
							{element}
						</article>
					))}
				</section>
				<div className={style.wrapperPolygon}>
					<div className={style.wrapperBlockFlex}>
						<div className={style.block}></div>
						<div className={style.block}></div>
						<div className={style.block}></div>
						<div className={style.block}></div>
					</div>
					<div
						className={
							clickedHidden
								? `${style.polygonDropDown}`
								: `${style.polygonDropDownRotate}`
						}
					></div>
				</div>
				{clickedHidden && (
					<ul>
						<li className={style.firstLi}>
							<div className={style.wrapperInput}>
								<img
									className={style.img_search}
									src={iconSearch}
									alt='search'
								/>
								<input
									onChange={handleOnChange}
									value={inputData}
									type='text'
									placeholder='Поиск'
								/>
							</div>
						</li>
						{args.map((element, index) => (
							<li
								className={style.lastLi}
								key={index}
								ref={el => (refsLi.current[index] = el)}
								onClick={() => {
									handleClickedElement(element, index)
								}}
								style={{
									backgroundColor:
										findSelectedElement(selectedElement, element) &&
										lastElement === element
											? '#00a5e9'
											: 'transparent',
									color:
										findSelectedElement(selectedElement, element) &&
										lastElement === element
											? '#fff'
											: '#99a8b2',
								}}
							>
								{findSelectedElement(selectedElement, element) && (
									<div
										className={
											lastElement === element
												? style.wrapperDoneIcon
												: style.wrapperDoneIconBLUE
										}
									>
										{console.log('element', element)}
										<img src={lastElement === element ? done : done2} alt='d' />
									</div>
								)}
								<div className={style.wrapperNameCategories}> {element}</div>
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	)
}
export default DropDownLang
