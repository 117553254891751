//http://localhost:4444/channel/my_channels
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
export const fetchUserChannel = createAsyncThunk(
	'userChannelsMy1/userChannelsMyFetch1',
	async () => {
		const token = await Cookies.get('token')
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_KEY}/channel/my_channels`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		)
		return data
	}
)
const initialState = {
	channelsOwner: [],
	statusChannel: 'loading', // loading | success | error
}

const FetchUserChannelsSlice = createSlice({
	name: 'channelsOwner',
	initialState,
	reducers: {
		setChannels(state, action) {
			state.channelsOwner = action.payload
			state.statusChannel = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUserChannel.pending, (state, action) => {
				state.statusChannel = 'loading'
			})
			.addCase(fetchUserChannel.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.channelsOwner = action.payload
				state.statusChannel = 'success'
			})
			.addCase(fetchUserChannel.rejected, (state, action) => {
				state.channelsOwner = []
				state.statusChannel = 'error'
			})
	},
})

export const { setChannels } = FetchUserChannelsSlice.actions

export default FetchUserChannelsSlice.reducer
