export function findMinMaxPricesArr(data) {
	let minPrice = Infinity
	let maxPrice = -Infinity

	data.forEach(item => {
		const filteredPrices = item.priceObjects.filter(
			po => po.time === 24 && po.hot === false
		)
		filteredPrices.forEach(po => {
			if (po.price < minPrice) minPrice = po.price
			if (po.price > maxPrice) maxPrice = po.price
		})
	})

	if (minPrice === Infinity) minPrice = null
	if (maxPrice === -Infinity) maxPrice = null

	return [Number(minPrice), Number(maxPrice)]
}
