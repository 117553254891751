import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
export const fetchFavoriteOneFetch = createAsyncThunk(
	'favoriteOneUniq/favoriteFetchOneUniq',
	async obj => {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_KEY}/user-channel-selection/selectone`,
			{
				params: {
					user_id: obj.user_id,
					id_channel: obj.id_channel,
				},
			}
		)

		return data.result
	}
)
const initialState = {
	favoriteOne: {},
	statusFavoriteOne: 'loading', // loading | success | error
}

const favoriteOneSlice = createSlice({
	name: 'favoriteOne',
	initialState,
	reducers: {
		setfavoriteOne(state, action) {
			state.favoriteOne = action.payload
			state.statusFavoriteOne = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchFavoriteOneFetch.pending, (state, action) => {
				state.statusFavoriteOne = 'loading'
			})
			.addCase(fetchFavoriteOneFetch.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.favoriteOne = action.payload
				state.statusFavoriteOne = 'success'
			})
			.addCase(fetchFavoriteOneFetch.rejected, (state, action) => {
				state.favoriteOne = {}
				state.statusFavoriteOne = 'error'
			})
	},
})

export default favoriteOneSlice.reducer
