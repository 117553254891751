import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
export const fetchFavoriteSlice = createAsyncThunk(
	'favorite/favoriteFetch',
	async obj => {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_KEY}/user-channel-selection/select`,
			{
				params: {
					user_id: obj.user_id,
					channels: obj.channels,
				},
			}
		)

		return data.result
	}
)
const initialState = {
	favorite: {},
	statusFavorite: 'loading', // loading | success | error
}

const FavoriteSlice = createSlice({
	name: 'favorite',
	initialState,
	reducers: {
		setFavorite(state, action) {
			state.favorite = action.payload
			state.statusFavorite = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchFavoriteSlice.pending, (state, action) => {
				state.statusFavorite = 'loading'
			})
			.addCase(fetchFavoriteSlice.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву

				state.favorite = action.payload
				state.statusFavorite = 'success'
			})
			.addCase(fetchFavoriteSlice.rejected, (state, action) => {
				state.favorite = {}
				state.statusFavorite = 'error'
			})
	},
})

export default FavoriteSlice.reducer
