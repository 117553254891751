import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchDataChannel = createAsyncThunk(
	'channel/ChannelFetch',
	async (arg, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_KEY}/channel/all_published`,
				{
					params: {
						all_published: true,
						name_channel: arg.name_channel,
						skip: arg.skip,
						take: arg.take,
						is_search: arg.is_search,
						is_sort: arg.sort.is_sort,
						sort_name: arg.sort.name,
						order: arg.sort.order,
					},
				}
			)
			return data
		} catch (error) {
			if (error.response && error.response.status === 404) {
				// Возвращаем специальное значение для 404 ошибки
				return rejectWithValue({ errorCode: 404 })
			}
			throw error
		}
	}
)

const initialState = {
	channels: [],
	status: 'loading', // loading | success | error
	statusNoMore: false,
}

export const fetchDataChannelSlice = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		setFetchDataChannel(state, action) {
			state.channels = action.payload
			state.status = 'success'
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchDataChannel.pending, (state, action) => {
				state.status = 'loading'
			})
			.addCase(fetchDataChannel.fulfilled, (state, action) => {
				// Добавление новых данных к существующему массиву
				if (JSON.parse(action.payload.is_search) === true) {
					state.channels = action.payload.allChannelsAndPrice
				} else {
					const newChannels =
						action.payload.allChannelsAndPrice.length > 0
							? action.payload.allChannelsAndPrice.filter(
									channel => !state.channels.some(c => c.id === channel.id)
							  )
							: []

					state.channels = [...state.channels, ...newChannels]
				}

				state.status = 'success'
			})
			.addCase(fetchDataChannel.rejected, (state, action) => {
				if (action.payload && action.payload.errorCode === 404) {
					// Специальная обработка для 404 ошибки
					state.status = 'success' // или любое другое состояние, которое вы хотите установить
					//state.channels = state.channels
					state.statusNoMore = true
				} else {
					state.channels = []
					state.status = 'error'
					state.statusNoMore = true
				}
			})
	},
})

export const { setFetchDataChannel } = fetchDataChannelSlice.actions
export default fetchDataChannelSlice.reducer
