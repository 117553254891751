export const sortPrice = priceSettings => {
	const priorityOrder = [24, 48, 72, 0, 1, 2]
	const sortedPriceSettings = priceSettings.slice().sort((a, b) => {
		const aIndex = priorityOrder.indexOf(a.current_time)
		const bIndex = priorityOrder.indexOf(b.current_time)

		if (aIndex === -1 && bIndex === -1) {
			return 0 // Если оба значения не найдены в priorityOrder, они считаются равными
		} else if (aIndex === -1) {
			return 1 // Если a.current_time не найдено в priorityOrder, оно считается меньше
		} else if (bIndex === -1) {
			return -1 // Если b.current_time не найдено в priorityOrder, оно считается меньше
		} else {
			return aIndex - bIndex // Сортируем по индексам в priorityOrder
		}
	})

	console.log('sortedPriceSettings', sortedPriceSettings)
	return sortedPriceSettings
}
